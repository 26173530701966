import React, { useContext, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
//Контексти
import { MainWindowContext } from './MainWindow'
//Компоненти
import { ReportHeader, SwitcherBetween, SwitcherDefault, TextWithArrow, DropDown, Button, SearchField, SvgButton, TableHeader, TableRow, ReportsDatePicker, SummaryRow, Loader } from '../Elements/ReportElements'
//Сервіси
import { generateGraphData, aggregateDataByDate, calculateTotalSummary } from '../../servises/graphData'
import { saveGraphData, loadGraphData, getStartAndEndDate, calculateAverageCR } from '../../servises/graphData';
//data
import { countriesIso2 } from '../../servises/data';
//svgs
import exportSVG from '../../images/export_close.svg'
import removeSVG from '../../images/remove_element.svg'
import modalOffSVG from '../../images/modal_off.svg'
import saveSVG from '../../images/save_template_close.svg'

export default function Reports() {
  const [isShowLoader, setIsShowLoader] = useState(false)
  const [isReady, setIsReady] = useState(false)
  const selectedColor = useSelector(state => state.settings.selectedColor);
  const colors = useSelector(state => state.settings.colors);

  // const globalGraphData = useSelector(state => state.settings.globalGraphData);
  const [globalGraphData, setGlobalGraphData] = useState({})

  const {
    agregatedGraphData, setAgregatedGraphData,
    calculatedTotalByAgregatesGraphData, setCalculatedTotalByAgregatesGraphData
  } = useContext(MainWindowContext)

  const [selectedDates, setSelectedDates] = useState({})

  const recalculateReports = async () => {
    setIsShowLoader(true);
    // Затримка на 1 секунду
    await new Promise(resolve => setTimeout(resolve, 700));
    setIsShowLoader(false);

    if (!globalGraphData) return
    const testAgregatedGraphData = aggregateDataByDate(selectedDates.start, selectedDates.end, globalGraphData)
    setCalculatedTotalByAgregatesGraphData({ ...calculatedTotalByAgregatesGraphData, ...calculateAverageCR(globalGraphData, selectedDates) })

    const sortedData = Object.entries(testAgregatedGraphData)
            .sort((a, b) => a[0].localeCompare(b[0]))
            .reduce((obj, [key, value]) => {
                obj[key] = value;
                return obj;
            }, {});

        setAgregatedGraphData(sortedData); 
    // setAgregatedGraphData(testAgregatedGraphData)
  }

  useEffect(() => {
    // const start = async() => {
    //   await new Promise(resolve => setTimeout(resolve, 700));
    //   setIsShowLoader(false);
    // }
    if (!isReady) return
    // start()
    recalculateReports()
  }, [globalGraphData])

  useEffect(() => {
    if (!globalGraphData.length) return
    setCalculatedTotalByAgregatesGraphData(calculateTotalSummary(agregatedGraphData))
    setCalculatedTotalByAgregatesGraphData({ ...calculatedTotalByAgregatesGraphData, ...calculateAverageCR(globalGraphData, selectedDates) })
  }, [agregatedGraphData])

  useEffect(() => {
    const start = () => {
      //
      const savedData = loadGraphData()
      if (savedData) {
        setSelectedDates(getStartAndEndDate(savedData))
        setGlobalGraphData(savedData)
        setIsReady(true)
        // setAgregatedGraphData(savedData)
      }
    }

    start()
    // setIsReady(true)
    setIsReady(true)
  }, [])

  if (!isReady) return <></>

  return (
    <article
      style={{
        position: 'relative',
        display: 'flex', flexDirection: 'column',
        justifyContent: 'flex-start', alignItems: 'center',
        // gap: '17px',

        marginTop: '70px',
        padding: '30px 15px 0vw 15px',

        backgroundColor: '#2F323B',
        minHeight: 'calc(100vh - 70px)',
        width: '100vw'
      }}>
      <ReportHeader />
      {/* SWITCHERS */}
      <section
        style={{
          display: 'flex', flexDirection: 'row',
          justifyContent: 'space-between', alignItems: 'center',
          width: '100%',
        }}
      >
        <section
          style={{
            display: 'flex', flexDirection: 'row',
            justifyContent: 'flex-start', alignItems: 'center',
            gap: '22px',
            height: '20px',
            marginTop: '5px',
          }}
        >
          <SwitcherBetween _title='Select a level' _offTitle='Manual' _onTitle='Presets' />
          <SwitcherDefault _title='Include Pricing' _offTitle='Off' _onTitle='On' />
        </section>
        <TextWithArrow _text='Clear Filters' />
      </section>
      {/* DROPDOWNS */}
      <section
        style={{
          display: 'flex', flexDirection: 'row',
          justifyContent: 'flex-start', alignItems: 'flex-end',
          gap: '22px',
          height: '60px', width: '100%',
          marginTop: '10px',
        }}
      >
        <ReportsDatePicker _onChage={setSelectedDates} />
        <DropDown _title='First Level*' _placeholder='First Level' _defaultValue='Country' _status='active' />
        <DropDown _title='Second Level' _placeholder='Second Level' _status='active' />
        <DropDown _title='Third Level' _placeholder='Third Level' _status='disactive' />
        <DropDown _title='Fourth Level' _placeholder='Fourth Level' _status='disactive' />
        <Button _text='Advanced Filter' />
      </section>
      {/* GENERATE BUTTON */}
      <section
        style={{
          display: 'flex', flexDirection: 'row',
          justifyContent: 'flex-start', alignItems: 'flex-end',
          width: '100%',
          marginTop: '17px',
        }}
      >
        <Button
          _onClick={() => recalculateReports()}
          _text='Generate'
          _styles={{
            padding: '0px 60px 0px 60px',
            backgroundColor: colors[selectedColor].fixed
          }}
          _textStyles={{
            color: 'black'
          }}
        />
      </section>
      {/* SEARCH FILTER */}
      <section
        style={{
          display: 'flex', flexDirection: 'row',
          justifyContent: 'space-between', alignItems: 'flex-end',
          marginTop: '40px',
          height: '50px', width: '100%',
        }}
      >
        {!isShowLoader ? 
          <p
            style={{
              color: 'white',
              fontSize: '13.5px'
            }}
          >
            {`Showing ${Object.keys(agregatedGraphData).length}/${Object.keys(agregatedGraphData).length} results`}
          </p> :
          <p></p>
        }

        <section
          style={{
            display: 'flex', flexDirection: 'row',
            justifyContent: 'flex-start', alignItems: 'center',
            gap: '8px',
            height: '50px',
          }}
        >
          <SearchField />
          <SvgButton _svg={exportSVG} />
          <SvgButton _svg={removeSVG} />
          <SvgButton _svg={saveSVG} />
          <SvgButton _svg={modalOffSVG} />
        </section>
      </section>
      {/* TABLE */}
      {/* <TableHeader /> */}
      {Object.keys(agregatedGraphData).length > 0 ? (
        <table>
          <TableHeader />
          <tbody>
            {!isShowLoader &&
              <>
                {Object.keys(agregatedGraphData).map(i => (
                  <TableRow _country={`${countriesIso2[i]} (${i})`} _leads={agregatedGraphData[i].leads} _ftds={agregatedGraphData[i].FTDs} _cr={agregatedGraphData[i].cr} />
                ))}
                <SummaryRow _calculatedTotalByAgregatesGraphData={calculatedTotalByAgregatesGraphData} />
              </>
            }

          </tbody>
        </table>
      ) :
        (

          <table>
            <TableHeader />
            <tbody>
              <section
                style={{
                  display: 'flex', flexDirection: 'row',
                  justifyContent: 'center', alignItems: 'center',
                  width: '100%'
                }}
              >
              </section>
            </tbody>
          </table>

        )
      }

      {isShowLoader && <Loader />}
    </article>
  )
}
