import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useSpring, animated } from 'react-spring';

import SettingsSection from './SettingsSection'
import Graph from './Graph';
import TopCampaign from './TopCampaign';
import DropDown from './DropDown';
import { SettingsWindow } from './SettingsSection';
import DateRangePicker from './DateRangePicker';
import { setGlobalGraphData } from '../store/settingsReducer';
//servises
import { getCountriesForDropdownList, getCampaignsForDropdownList } from '../servises/data';
import { saveGraphData, loadGraphData } from '../servises/graphData';
import Loader from './Loader';


export default function MainPage() {
    const [settingsToggle, setSettingsToogle] = useState(false)
    const [dropDownUpdate, setDropDownUpdate] = useState(0)
    const [graphDatdUpdate, setGraphDataUpdate] = useState(0)
    const [loaderShow, setLoaderShow] = useState(false)

    const showLoader = (callbackFunc) => {
        setLoaderShow(true)
        setDropDownUpdate(prevKey => prevKey + 1)
        setTimeout(() => {
            setGraphDataUpdate(prevKey => prevKey + 1)
            callbackFunc()
            setLoaderShow(false)
        }, 1400);
    }
    const generateRandomData = (numDays) => {
        const data = {};
        let currentDate = new Date('2023-05-11');

        for (let i = 0; i < numDays; i++) {
            const dateKey = currentDate.toISOString().split('T')[0];

            data[dateKey] = {
                leads: 0,
                impressions: 0,
                "FTD's": 0,
                clicks: 0
            };

            currentDate.setDate(currentDate.getDate() + 1);
        }

        return data;
    };
    const [graphData, setGraphData] = useState({})

    const [selectedDateRange, setSelectedDateRange] = useState([new Date(), new Date()])
    const [filterDataRange, setFilterDataRange] = useState([])

    const formatDate = (date) => {
        const d = new Date(date);
        const day = String(d.getDate()).padStart(2, '0');
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const year = d.getFullYear().toString().substr(-2);
        return `${day}/${month}/${year}`;
    };
    const changeFilterDataRange = (dataKey, period) => {
        const today = new Date();
        let dates = [];

        switch (dataKey) {
            case 'today':
                dates = [formatDate(today)];
                break;
            case 'yesterday':
                const yesterday = new Date(today);
                yesterday.setDate(yesterday.getDate() - 1);
                dates = [formatDate(yesterday)];
                break;
            case 'last7days':
                for (let i = 0; i < 7; i++) {
                    const day = new Date(today);
                    day.setDate(day.getDate() - i);
                    dates.unshift(formatDate(day));
                }
                break;
            case 'currentMonth':
                const firstDayCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1);
                const lastDayCurrentMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
                for (let d = firstDayCurrentMonth; d <= lastDayCurrentMonth; d.setDate(d.getDate() + 1)) {
                    dates.push(formatDate(d));
                }
                break;
            case 'lastMonth':
                const firstDayLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                const lastDayLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
                for (let d = firstDayLastMonth; d <= lastDayLastMonth; d.setDate(d.getDate() + 1)) {
                    dates.push(formatDate(d));
                }
                break;
            case 'period':
                const firstPeriodMonth = period[0];
                const lastPeriodMonth = period[1];
                for (let d = firstPeriodMonth; d <= lastPeriodMonth; d.setDate(d.getDate() + 1)) {
                    dates.push(formatDate(d));
                }
                break;
            default:
                break;
        }
        console.log(dates);
        if (dataKey == 'period') {
            showLoader(filterGraphDataByCountryList)
        }
        setFilterDataRange(dates);
        setSelectedDateRange(dates);
    };
    useEffect(() => {
        showLoader(filterGraphDataByCountryList)
    }, [filterDataRange])

    const [filteredCounties, setFilteredCountries] = useState([[]])
    const setCountriesFromDromDownSelect = (items) => {
        const newCountryList = items.map((i) => {
            return i.title
        })

        setFilteredCountries(newCountryList)
    }
    const [filteredEventsInfo, setFilterdEventsInfo] = useState({
        impressions: 0,
        clicks: 0,
        leads: 0,
        ftds: 0,
        conversions: 0,
        payout: 0
    })
    const [filteredGraphData, setFilteredGraphData] = useState(generateRandomData(10))
    const setGraphDataFromSettings = (data) => {
        setGraphData(data)
        filterGraphDataByCountryList()
    }
    const filterGraphDataByCountryList = (countryList = filteredCounties, datesList = filterDataRange) => {
        const filteredData = {};
        let totalImpressions = 0;
        let totalClicks = 0;
        let totalLeads = 0;
        let totalFTDs = 0;
        let totalPayout = 0;

        if (!datesList) return;
        // Check if countryList is empty or has null values, and if so, use all countries
        if (!countryList && Object.keys(graphData).length > 0 ||
            countryList && !countryList[0] && Object.keys(graphData).length > 0 ||
            !countryList && countryList[0] && Object.keys(graphData).length > 0 ||
            countryList && countryList[0].length == 0 && Object.keys(graphData).length > 0) {

            countryList = graphData ? Object.keys(graphData[Object.keys(graphData)[0]]) : [];
        }

        const singleDate = datesList.length === 1;

        datesList.forEach(date => {
            if (singleDate) {
                // Ініціалізуємо об'єкт для зберігання даних по годинах
                filteredData[date] = { byHours: {} };
                for (let hour = 0; hour < 24; hour++) {
                    filteredData[date].byHours[hour] = {
                        leads: 0,
                        impressions: 0,
                        "FTD's": 0,
                        clicks: 0
                    };
                }
            } else {
                filteredData[date] = {
                    leads: 0,
                    impressions: 0,
                    "FTD's": 0,
                    clicks: 0
                };
            }
            try {


                countryList.forEach(country => {
                    if (graphData[date] && graphData[date][country]) {
                        if (singleDate) {

                            const countryData = graphData[date][country];
                            // Якщо є лише одна дата, агрегуємо дані по годинах
                            Object.keys(graphData[date][country].byHours).forEach(hour => {
                                const hourData = graphData[date][country].byHours[hour];
                                filteredData[date].byHours[hour].leads += hourData.leads;
                                filteredData[date].byHours[hour].impressions += hourData.impressions;
                                filteredData[date].byHours[hour]["FTD's"] += hourData["FTD's"];
                                filteredData[date].byHours[hour].clicks += hourData.clicks;

                                totalLeads += hourData.leads;
                                totalImpressions += hourData.impressions;
                                totalFTDs += hourData["FTD's"];
                                totalClicks += hourData.clicks;
                                totalPayout += hourData["FTD's"] * countryData.price;
                            });
                        } else {
                            // Для діапазону дат, агрегуємо загальні дані
                            const countryData = graphData[date][country];
                            filteredData[date].leads += countryData.leads;
                            filteredData[date].impressions += countryData.impressions;
                            filteredData[date]["FTD's"] += countryData["FTD's"];
                            filteredData[date].clicks += countryData.clicks;

                            totalLeads += countryData.leads;
                            totalImpressions += countryData.impressions;
                            totalFTDs += countryData["FTD's"];
                            totalClicks += countryData.clicks;
                            totalPayout += countryData["FTD's"] * countryData.price;
                        }
                    }
                });
            } catch {
                console.log(graphData);
            }
        });


        // console.log(filteredData[Object.keys(filteredData)[0]].byHours)
        try {
            setFilteredGraphData(datesList.length > 1 ? filteredData : filteredData[Object.keys(filteredData)[0]].byHours);
        } catch {

        }

        setFilterdEventsInfo({
            impressions: totalImpressions,
            clicks: totalClicks,
            leads: totalLeads,
            ftds: totalFTDs,
            conversions: totalClicks > 0 ? parseFloat(((totalFTDs / totalLeads) * 100).toFixed(2)) : 0,
            payout: totalPayout
        });
    };
    function groupDataByMonth(data) {
        // Convert keys to dates and sort them
        let dates = Object.keys(data).map(key => {
            const [day, month, year] = key.split('/').map(Number);
            return new Date(year + 2000, month - 1, day); // Adjust the year as needed
        });
        dates.sort((a, b) => a - b);

        // Check if the range is more than 3 months
        let moreThanThreeMonths = monthsBetween(dates[0], dates[dates.length - 1]) > 3;

        if (!moreThanThreeMonths) {
            return data; // Return original data if less than 3 months
        }

        let monthlyData = {};

        // Group data by month and year
        Object.keys(data).forEach(key => {
            const [day, month, year] = key.split('/');
            const monthYear = `${month}/${year}`;

            if (!monthlyData[monthYear]) {
                monthlyData[monthYear] = { ...data[key] }; // Initialize with first entry
            } else {
                Object.keys(data[key]).forEach(metric => {
                    monthlyData[monthYear][metric] += data[key][metric]; // Sum up metrics
                });
            }
        });

        return monthlyData;
    }
    function monthsBetween(date1, date2) {
        let months;
        months = (date2.getFullYear() - date1.getFullYear()) * 12;
        months -= date1.getMonth();
        months += date2.getMonth();
        return months <= 0 ? 0 : months;
    }
    const groupedData = groupDataByMonth(filteredGraphData);
    const isGrouped = Object.keys(filteredGraphData).length > 90;
    const dataSource = isGrouped ? groupedData : filteredGraphData;

    const labels = Object.keys(dataSource);

    const dataLeads = labels.map(label => dataSource[label].leads);
    const dataImpressions = labels.map(label => dataSource[label].impressions);
    const dataFTDs = labels.map(label => dataSource[label]["FTD's"]);
    const dataClicks = labels.map(label => dataSource[label].clicks);

    useEffect(() => {
        const start = () => {
          //
          const savedData = loadGraphData()
          if(savedData){
            setGraphData(savedData)
            changeFilterDataRange('last7days')
          }
        }
    
        start()
      },[])

    return (
        <article style={{
            position: 'relative',

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '17px',

            marginTop: '70px',
            padding: '30px 15px 0vw 15px',

            backgroundColor: '#2F323B',
            minHeight: 'calc(100vh - 70px)',
            width: '100vw'
        }}>
            <DateSection setDataRange={changeFilterDataRange} showLoader={showLoader} filterDataRange={selectedDateRange} changeFilterDataRange={changeFilterDataRange} />
            <DataSelectorSection onFilter={() => { showLoader(filterGraphDataByCountryList) }} onCountrySelect={setCountriesFromDromDownSelect} dropDownUpdate={dropDownUpdate} />
            <Graph
                labels={labels}
                dataLeads={dataLeads}
                dataImpressions={dataImpressions}
                dataFTDs={dataFTDs}
                dataClicks={dataClicks}
                graphInfoData={filteredEventsInfo}
                update={graphDatdUpdate}
            />
            <TopCampaign />
            <SettingsSection settingsToggle={settingsToggle} setSettingsToggle={setSettingsToogle} />
            <SettingsWindow toggle={settingsToggle} setSettingsWindowToggle={setSettingsToogle} setGraphDataFromSettings={setGraphDataFromSettings} changeFilterDataRange={changeFilterDataRange} countriesSelected={filteredCounties} />

            {loaderShow && <Loader />}
        </article>

    )
}

const DateSection = ({ setDataRange, showLoader, filterDataRange, changeFilterDataRange }) => {
    const [dateItems, setDateItems] = useState({
        today: {
            isSelect: false,
            title: 'Today',
        },
        yesterday: {
            isSelect: false,
            title: 'Yesterday',
        },
        last7days: {
            isSelect: false,
            title: 'Last 7 days',
        },
        currentMonth: {
            isSelect: false,
            title: 'Current Month',
        },
        lastMonth: {
            isSelect: false,
            title: 'Last Month',
        }
    })
    const selectDate = (selectedKey) => {
        const updatedItems = Object.keys(dateItems).reduce((acc, key) => {
            acc[key] = {
                ...dateItems[key],
                isSelect: key === selectedKey
            };
            return acc;
        }, {});

        setDateItems(updatedItems);
    }
    useEffect(() => {
        selectDate('last7days')
        selectDate('last7days')
    }, [])

    return (
        <section style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',

            width: '100%',
            height: '45px',
            backgroundColor: '#23242c'
        }}>
            {Object.keys(dateItems).map(k => <DataSectionItem key={k} isSelect={dateItems[k].isSelect} title={dateItems[k].title} onClick={() => { selectDate(k); setDataRange(k); }} />)}

            <DateRangePicker filterDatesSelect={filterDataRange} changeFilterDataRange={changeFilterDataRange} />
        </section>
    )
}
const DataSectionItem = ({ isSelect, title, onClick }) => {
    const [isHovered, setIsHovered] = useState(false);
    const selectedColor = useSelector(state => state.settings.selectedColor);
    const colors = useSelector(state => state.settings.colors);

    const hoverColor = !isSelect ? '#1f2227' : colors[selectedColor].gradienStart
    const staticColor = !isSelect ? 'transparent' : colors[selectedColor].fixed
    const textColor = !isSelect ? 'white' : 'black'

    return (
        <button
            style={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                border: 'none',
                backgroundColor: isHovered ? hoverColor : staticColor,
                color: textColor,

                fontWeight: '500',
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={onClick}
        >
            <p>{title}</p>
        </button>
    );
}
const DataSelectorSection = ({ onFilter, onCountrySelect, dropDownUpdate }) => {
    const selectedColor = useSelector(state => state.settings.selectedColor);
    const colors = useSelector(state => state.settings.colors);
    const [dropDownStatuses, setDropDownStatuses] = useState([false, false, false])
    const openDropDownItem = (_id) => {
        setDropDownStatuses(
            dropDownStatuses.map((status, index) => index === _id ? !status : false)
        );
    };

    useEffect(() => {
        setDropDownStatuses([false, false, false])
    }, [dropDownUpdate])

    return (
        <section style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '30px',
            width: '100%',
            height: '50px',
        }}>
            <DropDown defaultTitle='All Affiliate' status={dropDownStatuses[0]} onOpen={() => { openDropDownItem(0) }} />
            <DropDown defaultTitle='All Country' status={dropDownStatuses[1]} onOpen={() => { openDropDownItem(1) }} dropDownItems={getCountriesForDropdownList()} onCountrySelect={onCountrySelect} />
            <DropDown defaultTitle='All Campaign' status={dropDownStatuses[2]} onOpen={() => { openDropDownItem(2) }} dropDownItems={getCampaignsForDropdownList()} />
            <section style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                minWidth: '220px',
                minHeight: '35px',
                borderRadius: '5px',
                border: 'none',
                paddingLeft: '8px',
                paddingRight: '5px',
                cursor: 'pointer',
            }}>
                <p style={{
                    fontSize: '14px',
                    color: '#bbbbbb',
                    fontWeight: '500'
                }}>Distribution Affiliates</p>
                <Switcher />
            </section>
            <button onClick={onFilter} style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '220px',
                minHeight: '35px',
                background: `linear-gradient(to right, ${colors[selectedColor].gradienStart}, ${colors[selectedColor].gradientEnd})`,
                borderRadius: '5px',
                border: 'none',
                paddingLeft: '8px',
                paddingRight: '5px',
                cursor: 'pointer',
            }}><p style={{
                fontSize: '14px',
                color: '#050301',
                fontWeight: '500'
            }}>Filter</p></button>
        </section>
    )
}
const Switcher = () => {
    const selectedColor = useSelector(state => state.settings.selectedColor);
    const colors = useSelector(state => state.settings.colors);

    const [status, setStatus] = useState(false)
    const switcherAnimation = useSpring({
        left: !status ? '2px' : '19px',
        backgroundColor: status ? colors[selectedColor].fixed : '#55575b'
    })

    return (
        <section style={{

            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px'
        }}>
            <section onClick={() => { setStatus(!status) }} style={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: '17px',
                width: '35px',
                backgroundColor: '#1c1e24',
                marginLeft: '10px',
                borderRadius: '8px',
                // overflow: 'hidden',
                paddingLeft: '1px',
                paddingRight: '1px',
            }}>
                <animated.section style={{
                    ...switcherAnimation,
                    position: 'absolute',
                    height: '80%',
                    aspectRatio: '1/1',
                    content: 'none',
                    borderRadius: '100%',
                }}>

                </animated.section>
            </section>
            <p style={{
                fontSize: '14px',
                color: '#bbbbbb',
                fontWeight: '500'
            }}>{!status ? 'Off' : 'On'}</p>
        </section>
    )
}
