export const generateGraphData = (settingItems, selectedDate) => {
  function formatDate(date) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [day, month, year].join('/');
  }

  function parseDate(dateString) {
    const [day, month, year] = dateString.split('/').map(num => parseInt(num, 10));
    return new Date(year < 100 ? year + 2000 : year, month - 1, day);
  }

  function distributeEvenly(value, hoursToDistribute) {
    let distribution = new Array(hoursToDistribute).fill(0);
    let distributed = 0;
    while (distributed < value) {
      let randomIndex = Math.floor(Math.random() * hoursToDistribute);
      distribution[randomIndex]++;
      distributed++;
    }
    return distribution;
  }

  function distributeMetricsOverHours(metrics, currentDate, totalDays) {
    const hoursToDistribute = 24; // Припускаємо, що метрики розподіляються за всі 24 години
    const impressions = distributeEvenly(metrics.impressions, hoursToDistribute);
    const clicks = distributeEvenly(metrics.clicks, hoursToDistribute);
    const leads = distributeEvenly(metrics.leads, hoursToDistribute);
    const ftds = distributeEvenly(metrics["FTD's"], hoursToDistribute);

    let hourlyMetrics = {};
    for (let hour = 0; hour < hoursToDistribute; hour++) {
      hourlyMetrics[hour] = {
        impressions: impressions[hour],
        clicks: clicks[hour],
        leads: leads[hour],
        "FTD's": ftds[hour]
      };
    }
    return hourlyMetrics;
  }

  function deepMergeObjects(obj1, obj2) {
    let merged = { ...obj1 };
    for (let key in obj2) {
      if (merged[key] && typeof merged[key] === 'object' && typeof obj2[key] === 'object') {
        merged[key] = deepMergeObjects(merged[key], obj2[key]);
      } else {
        merged[key] = obj2[key];
      }
    }
    return merged;
  }

  function mergeGraphData(graphData1, graphData2) {
    let mergedGraphData = { ...graphData1 };
    for (let date in graphData2) {
      if (!mergedGraphData[date]) {
        mergedGraphData[date] = {};
      }
      for (let country in graphData2[date]) {
        if (!mergedGraphData[date][country]) {
          mergedGraphData[date][country] = graphData2[date][country];
        } else {
          mergedGraphData[date][country] = deepMergeObjects(mergedGraphData[date][country], graphData2[date][country]);
        }
      }
    }
    return mergedGraphData;
  }

  if (!selectedDate[0] || !selectedDate[1]) {
    alert('Виберіть дату');
    return;
  }

  const startDate = parseDate(selectedDate[0]);
  const endDate = parseDate(selectedDate[1]);
  const totalDays = Math.round((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1;
  let graphData = {};

  for (let day = 0; day < totalDays; day++) {
    const currentDate = new Date(startDate);
    currentDate.setDate(currentDate.getDate() + day);
    const dateKey = formatDate(currentDate);
    graphData[dateKey] = {};

    settingItems.forEach(item => {
      graphData[dateKey][item.country] = {
        leads: 0,
        clicks: 0,
        impressions: 0,
        "FTD's": 0,
        price: item.profitPerPayout,
        byHours: {}
      };
    });
  }

  settingItems.forEach(item => {
    let remainingLeads = item.leads;
    let remainingPayouts = item.payouts;

    for (let i = 0; i < remainingLeads; i++) {
      const randomDayIndex = Math.floor(Math.random() * totalDays);
      const randomDate = new Date(startDate);
      randomDate.setDate(randomDate.getDate() + randomDayIndex);
      const dateKey = formatDate(randomDate);
      graphData[dateKey][item.country].leads++;
    }

    for (let i = 0; i < remainingPayouts; i++) {
      const randomDayIndex = Math.floor(Math.random() * totalDays);
      const randomDate = new Date(startDate);
      randomDate.setDate(randomDate.getDate() + randomDayIndex);
      const dateKey = formatDate(randomDate);
      graphData[dateKey][item.country]["FTD's"]++;
    }

    for (const date in graphData) {
      const leadsForDate = graphData[date][item.country].leads;
      const clickMultiplier = 1.3 + Math.random() * 0.1;
      const clicksForDate = Math.floor(leadsForDate * clickMultiplier);
      graphData[date][item.country].clicks += clicksForDate;

      const impressionsForDate = graphData[date][item.country].clicks + Math.floor(Math.random() * 6);
      graphData[date][item.country].impressions += impressionsForDate;

      const metrics = {
        leads: graphData[date][item.country].leads,
        impressions: impressionsForDate,
        clicks: graphData[date][item.country].clicks,
        "FTD's": graphData[date][item.country]["FTD's"]
      };
      const hourlyMetrics = distributeMetricsOverHours(metrics, date, totalDays);
      graphData[date][item.country].byHours = hourlyMetrics;
    }
  });

  return graphData;
}
export const aggregateDataByDate = (startDate, endDate, dataObject) => {
  function parseDate(dateString) {
    const [day, month, year] = dateString.split('/');
    return new Date(`20${year}`, month - 1, day);
  }

  function calculateConversionRate(results) {
    for (const country in results) {
      if (results.hasOwnProperty(country)) {
        const countryData = results[country];
        if (countryData.impressions > 0) {
          countryData.cr = parseFloat((countryData.FTDs / countryData.leads * 100).toFixed(2));
        } else {
          countryData.cr = 0;
        }
      }
    }
  }

  const parsedStartDate = parseDate(startDate);
  const parsedEndDate = parseDate(endDate);
  const results = {};

  for (const date in dataObject) {
    if (dataObject.hasOwnProperty(date)) {
      const currentDataDate = parseDate(date);
      
      if (currentDataDate >= parsedStartDate && currentDataDate <= parsedEndDate) {
        for (const country in dataObject[date]) {
          if (dataObject[date].hasOwnProperty(country)) {
            if (!results[country]) {
              results[country] = { leads: 0, clicks: 0, impressions: 0, FTDs: 0, price: 0 };
            }
            results[country].leads += dataObject[date][country].leads || 0;
            results[country].clicks += dataObject[date][country].clicks || 0;
            results[country].impressions += dataObject[date][country].impressions || 0;
            results[country].FTDs += dataObject[date][country]["FTD's"] || 0;
            results[country].price += dataObject[date][country].price || 0;
          }
        }
      }
    }
  }

  calculateConversionRate(results);
  return results;
}
export const calculateTotalSummary = (results) => {
  const totalSummary = { leads: 0, clicks: 0, impressions: 0, FTDs: 0, price: 0, averageCR: 0 };
  let totalCR = 0;
  let countryCount = 0;

  for (const country in results) {
    if (results.hasOwnProperty(country)) {
      totalSummary.leads += results[country].leads;
      totalSummary.clicks += results[country].clicks;
      totalSummary.impressions += results[country].impressions;
      totalSummary.FTDs += results[country].FTDs;
      totalSummary.price += results[country].price;

      if (results[country].impressions > 0) {
        totalCR +=  parseFloat((results[country].FTDs / results[country].leads * 100).toFixed(2));
        countryCount++;
      }
    }
  }

  totalSummary.averageCR = countryCount > 0 ? parseFloat(totalCR / countryCount).toFixed(2) : 0;

  return totalSummary;
};
export const saveGraphData = (graphData) => {
  localStorage.setItem('graphData', JSON.stringify(graphData));
  const savedData = JSON.parse(localStorage.getItem('graphData'));
  console.log('Збережні нові дані для графіку: ', savedData);
}
export const saveSettingsItems = (settingsItems) => {
  localStorage.setItem('settingsItems', JSON.stringify(settingsItems));
  const savedData = JSON.parse(localStorage.getItem('settingsItems'));
  console.log('Збережні нові дані налаштування: ', savedData);
}
export const loadGraphData = () => {
  const savedDataString = localStorage.getItem('graphData');
  if (savedDataString) {
    const graphData = JSON.parse(savedDataString);
    console.log("Збережені дані графіку: ", graphData);
    return graphData;
  } else {
    console.log("Немає збережених даних графіку.");
    return null;
  }
}
export const loadSettingsItems = () => {
  const settingsItemsString = localStorage.getItem('settingsItems');
  if (settingsItemsString) {
    const settingsItems = JSON.parse(settingsItemsString);
    console.log("Збережені дані налаштувань: ", settingsItems);
    return settingsItems;
  } else {
    console.log("Немає збережених даних налаштувань.");
    return null;
  }
}
export const getStartAndEndDate = (data) => {
  const sortedKeys = Object.keys(data).sort((a, b) => new Date(a) - new Date(b));
  return {
    start: sortedKeys[0],
    end: sortedKeys[sortedKeys.length - 1]
  };
}
export const calculateAverageCR = (graphData, selectedDate) => {
  console.log(graphData,selectedDate);
  // if(!graphData.length) return {}

  let totalLeads = 0;
  let totalFTDs = 0;
  // Функція для перетворення строки дати у форматі "DD/MM/YY" в об'єкт Date
  const parseDate = (dateStr) => {
    const [day, month, year] = dateStr.split('/').map(Number);
    return new Date(year + 2000, month - 1, day);
  };

  // Перетворюємо строки selectedDate в об'єкти Date
  const startDate = parseDate(selectedDate.start);
  const endDate = parseDate(selectedDate.end);

  // Ітерація через кожну дату в об'єкті
  Object.keys(graphData).forEach(date => {
    const dateData = graphData[date];
    const currentDate = parseDate(date);

    // Перевірка чи поточна дата входить в обраний проміжок
    if (currentDate >= startDate && currentDate <= endDate) {
      // Ітерація через кожну країну в даті
      Object.keys(dateData).forEach(country => {
        const countryData = dateData[country];

        // Сумування кількості leads та FTDs
        totalLeads += countryData.leads || 0;
        totalFTDs += countryData["FTD's"] || 0;
      });
    }
  });

  // Обчислення середнього конверсійного відсотка
  const averageCR = totalLeads > 0 ? (totalFTDs / totalLeads * 100) : 0;
  // Заокруглення до двох знаків після коми
  return ( { 
    averageCR: parseFloat(averageCR.toFixed(2)),
    totalLeads,
    totalFTDs
  });
}

