import React, { useEffect, useState, forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSpring, animated } from "react-spring";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import premiumSvg from "../../images/premium.svg";

export const TableHeader = () => {
  const pStyle = {
    fontSize: "15.5px",
    fontWeight: 500,
    color: "#7f8086",
  };
  const thStyle = {
    verticalAlign: "center",
    backgroundColor: "transparent",
    border: "none",
  };
  const ThElement = ({ _title }) => {
    return (
      <th
        style={{
          ...thStyle,
        }}
      >
        <p
          style={{
            ...pStyle,
          }}
        >
          {_title}
        </p>
      </th>
    );
  };
  const ThElementWithTwoColumns = ({ _column, _columnTwo }) => {
    return (
      <th
        style={{
          ...thStyle,
          maxWidth: "120px",
        }}
      >
        <section
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignContent: "center",
            gap: "60px",
          }}
        >
          <p
            style={{
              ...pStyle,
            }}
          >
            {_column}
          </p>
          <img
            src={_columnTwo}
            style={{
              height: "1em",
            }}
          ></img>
        </section>
      </th>
    );
  };
  const ThElementWithtTwoRow = ({ _titleRow, _titleRowSecond }) => {
    return (
      <th
        style={{
          ...thStyle,
        }}
      >
        <section
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p
            style={{
              ...pStyle,
            }}
          >
            {_titleRow}
          </p>
          <p
            style={{
              ...pStyle,
              fontSize: "10px",
            }}
          >
            {_titleRowSecond}
          </p>
        </section>
      </th>
    );
  };
  return (
    <thead>
      <tr
        style={{
          backgroundColor: "transparent",
        }}
      >
        <ThElementWithTwoColumns _column="Insights" _columnTwo={premiumSvg} />
        <ThElement _title="Name" />
        <ThElement _title="Leads" />
        <ThElement _title="FTDs" />
        <ThElementWithtTwoRow _titleRow="CR" _titleRowSecond="FT..." />
      </tr>
    </thead>
  );
};
export const TableRow = ({ _country, _leads, _ftds, _cr }) => {
  const selectedColor = useSelector((state) => state.settings.selectedColor);
  const colors = useSelector((state) => state.settings.colors);
  const pStyle = {
    fontSize: "15.5px",
    fontWeight: 500,
    color: "white",
  };
  const thStyle = {
    columnSpan: "100%",
    verticalAlign: "center",
    backgroundColor: "#282a32",
    border: "none",
    borderBottom: "5px solid #2F323B",
  };
  const TdElementInsights = () => {
    return (
      <td
        style={{
          ...thStyle,
          maxWidth: "120px",
        }}
      >
        <section
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: "33px",
            width: "160px",
            borderRadius: "30px",
            backgroundColor: colors[selectedColor].fixed,
            cursor: "pointer",
          }}
        >
          <p
            style={{
              ...pStyle,
              fontSize: "13px",
              color: "rgba(0,0,0,.7)",
            }}
          >
            24 Insights
          </p>
        </section>
      </td>
    );
  };
  const ThElement = ({ _title }) => {
    return (
      <th
        style={{
          ...thStyle,
        }}
      >
        <section
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "4px",
          }}
        >
          <InfoItem />
          <p
            style={{
              ...pStyle,
            }}
          >
            {_title}
          </p>
        </section>
      </th>
    );
  };
  return (
    <tr
      style={{
        border: "none",
        padding: "0px",
      }}
    >
      <TdElementInsights />
      <ThElement _title={_country} />
      <ThElement _title={_leads} />
      <ThElement _title={_ftds} />
      <ThElement _title={`${_cr}%`} />
    </tr>
  );
};
export const SummaryRow = ({ _calculatedTotalByAgregatesGraphData }) => {
  const selectedColor = useSelector((state) => state.settings.selectedColor);
  const colors = useSelector((state) => state.settings.colors);
  const pStyle = {
    fontSize: "15.5px",
    fontWeight: 500,
    color: colors[selectedColor].fixed,
  };
  const thStyle = {
    columnSpan: "100%",
    verticalAlign: "center",
    backgroundColor: "transparent",
    border: "none",
  };
  const ThElement = ({ _title }) => {
    return (
      <th
        style={{
          ...thStyle,
        }}
      >
        <section
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "4px",
          }}
        >
          <p
            style={{
              ...pStyle,
            }}
          >
            {_title}
          </p>
        </section>
      </th>
    );
  };
  console.log(_calculatedTotalByAgregatesGraphData);
  return (
    <tr
      style={{
        border: "none",
        backgroundColor: "transparent",
        padding: "0px",
      }}
    >
      <ThElement _title="" />
      <ThElement _title="Total" />
      <ThElement _title={_calculatedTotalByAgregatesGraphData.totalLeads} />
      <ThElement _title={_calculatedTotalByAgregatesGraphData.totalFTDs} />
      <ThElement
        _title={`${_calculatedTotalByAgregatesGraphData.averageCR}%`}
      />
    </tr>
  );
};
export const ReportHeader = () => {
  return (
    <section
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        height: "25px",
        width: "100%",
      }}
    >
      <p
        style={{
          color: "white",
          fontSize: "26px",
          fontWeight: 700,
        }}
      >
        Reports
      </p>
    </section>
  );
};
export const SwitcherBetween = ({ _title, _onTitle, _offTitle }) => {
  const selectedColor = useSelector((state) => state.settings.selectedColor);
  const colors = useSelector((state) => state.settings.colors);

  const [status, setStatus] = useState(false);

  const switcherAnimation = useSpring({
    left: !status ? "2px" : "19px",
    backgroundColor: colors[selectedColor].fixed,
  });

  return (
    <section
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <p
        style={{
          marginRight: "20px",
          fontSize: "15px",
          color: colors[selectedColor].fixed,
          fontWeight: "500",
        }}
      >
        {_title}
      </p>

      <p
        style={{
          fontSize: "13px",
          color: !status ? "white" : "#656a6f",
          fontWeight: "500",
        }}
      >
        {_offTitle}
      </p>

      <section
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <section
          onClick={() => {
            setStatus(!status);
          }}
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            height: "17px",
            width: "35px",
            backgroundColor: "#1c1e24",
            marginLeft: "15px",
            marginRight: "5px",
            paddingLeft: "1px",
            paddingRight: "1px",
            borderRadius: "8px",
          }}
        >
          <animated.section
            style={{
              ...switcherAnimation,
              position: "absolute",
              height: "80%",
              aspectRatio: "1/1",
              content: "none",
              borderRadius: "100%",
            }}
          ></animated.section>
        </section>

        <p
          style={{
            fontSize: "13px",
            color: !status ? "#656a6f" : "white",
            fontWeight: "500",
          }}
        >
          {_onTitle}
        </p>
      </section>
    </section>
  );
};
export const SwitcherDefault = ({ _title, _onTitle, _offTitle }) => {
  const selectedColor = useSelector((state) => state.settings.selectedColor);
  const colors = useSelector((state) => state.settings.colors);

  const [status, setStatus] = useState(false);

  const switcherAnimation = useSpring({
    left: !status ? "2px" : "19px",
    backgroundColor: status ? colors[selectedColor].fixed : "#55575b",
  });

  return (
    <section
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <p
        style={{
          marginRight: "20px",
          fontSize: "15px",
          color: colors[selectedColor].fixed,
          fontWeight: "500",
        }}
      >
        {_title}
      </p>

      <p
        style={{
          fontSize: "13px",
          color: "white",
          fontWeight: "500",
        }}
      >
        {_offTitle}
      </p>

      <section
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <section
          onClick={() => {
            setStatus(!status);
          }}
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            height: "17px",
            width: "35px",
            backgroundColor: "#1c1e24",
            marginLeft: "15px",
            marginRight: "5px",
            paddingLeft: "1px",
            paddingRight: "1px",
            borderRadius: "8px",
          }}
        >
          <animated.section
            style={{
              ...switcherAnimation,
              position: "absolute",
              height: "80%",
              aspectRatio: "1/1",
              content: "none",
              borderRadius: "100%",
            }}
          ></animated.section>
        </section>

        <p
          style={{
            fontSize: "13px",
            color: "white",
            fontWeight: "500",
          }}
        >
          {_onTitle}
        </p>
      </section>
    </section>
  );
};
export const DropDown = ({
  _title,
  _placeholder,
  _defaultValue,
  _items,
  _status,
}) => {
  const [selectedValue, setSelectedValue] = useState(_defaultValue);
  const [status, setStatus] = useState("close");

  const toggle = () => {
    setStatus((prev) => (prev === "close" ? "open" : "close"));
  };

  const arrowAnimation = useSpring({
    transform: status === "open" ? "rotate(180deg)" : "rotate(0deg)",
  });

  return (
    <section
      style={{
        position: "relative",
      }}
    >
      <p
        style={{
          paddingLeft: "10px",
          marginBottom: "5px",
          fontSize: "13px",
          color: "#9e9ea0",
          fontWeight: "500",
        }}
      >
        {_title}
      </p>
      {_status === "active" && (
        <button
          onClick={toggle}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            minWidth: "175px",
            minHeight: "35px",
            backgroundColor: "#484b54",
            borderRadius: "5px",
            border: "none",
            paddingLeft: "8px",
            paddingRight: "5px",
            cursor: "pointer",
          }}
        >
          <p
            style={{
              paddingLeft: "3px",
              fontSize: "14px",
              color: selectedValue ? "white" : "#9e9ea0",
              fontWeight: "600",
            }}
          >
            {selectedValue ? selectedValue : _placeholder}
          </p>
          <animated.span
            className="material-symbols-outlined"
            style={{ ...arrowAnimation, color: "white" }}
          >
            expand_more
          </animated.span>
        </button>
      )}
      {_status === "disactive" && (
        <button
          onClick={toggle}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            minWidth: "175px",
            minHeight: "35px",
            backgroundColor: "#393c45",
            borderRadius: "5px",
            border: "none",
            paddingLeft: "8px",
            paddingRight: "5px",
            cursor: "pointer",
          }}
        >
          <p
            style={{
              paddingLeft: "3px",
              fontSize: "14px",
              color: "#4c4e55",
              fontWeight: "600",
            }}
          >
            {selectedValue ? selectedValue : _placeholder}
          </p>
        </button>
      )}

      <section style={{}}></section>
    </section>
  );
};
export const Button = ({ _text, _onClick, _styles, _textStyles }) => {
  return (
    <button
      onClick={_onClick && _onClick}
      style={{
        padding: "0px 30px 0px 30px",
        height: "35px",
        backgroundColor: "#121317",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
        ..._styles,
      }}
    >
      <p
        style={{
          fontSize: "14px",
          fontWeight: 500,
          color: "white",
          ..._textStyles,
        }}
      >
        {_text}
      </p>
    </button>
  );
};
export const TextWithArrow = ({ _text }) => {
  return (
    <section
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "3px",
      }}
    >
      <p
        style={{
          fontSize: "12px",
          fontWeight: 500,
          color: "white",
        }}
      >
        {_text}
      </p>
      <span className="material-symbols-outlined" style={{ color: "white" }}>
        expand_more
      </span>
    </section>
  );
};
export const SearchField = () => {
  const SearchButton = () => {
    return (
      <button
        style={{
          position: "absolute",
          right: "0px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          height: "42px",
          width: "42px",
          border: "1px solid white",
          borderRadius: "50%",
          backgroundColor: "#2f323b",
          cursor: "pointer",
        }}
      >
        <span
          className="material-symbols-outlined"
          style={{
            cursor: "pointer",
            color: "white",
          }}
        >
          arrow_forward_ios
        </span>
      </button>
    );
  };

  return (
    <section
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "5px",
        padding: "0px 45px 0px 5px",
        backgroundColor: "#494b54",
        borderRadius: "20px",
        height: "40px",
        width: "350px",
      }}
    >
      <section
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "5px",
        }}
      >
        <span
          className="material-symbols-outlined"
          style={{
            cursor: "pointer",
            color: "white",
          }}
        >
          expand_more
        </span>
        <p
          style={{
            cursor: "pointer",
            fontSize: "14px",
            fontWeight: "500",
            color: "#b4b4b5",
          }}
        >
          Search by Name
        </p>
      </section>
      <section
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          height: "100%",
          gap: "0px",
        }}
      >
        <span
          className="material-symbols-outlined"
          style={{
            cursor: "pointer",
            fontWeight: 100,
            fontSize: "27px",
            color: "white",
          }}
        >
          close
        </span>
        <span
          className="material-symbols-outlined"
          style={{
            cursor: "pointer",
            fontWeight: 200,
            fontSize: "21px",
            color: "white",
          }}
        >
          search
        </span>
      </section>

      <SearchButton />
    </section>
  );
};
export const SvgButton = ({ _svg, _onClick }) => {
  return (
    <button
      style={{
        cursor: "pointer",
        backgroundColor: "transparent",
        border: "none",
      }}
    >
      <img
        src={_svg}
        style={{
          height: "41px",
          width: "41px",
        }}
      ></img>
    </button>
  );
};
export const InfoItem = () => {
  return (
    <section
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        width: "15px",
        height: "15px",
        borderRadius: "50%",
        border: "1px solid #57585e",
        cursor: "pointer",
      }}
    >
      <p
        style={{
          fontSize: "9px",
          fontWeight: 700,
          color: "#57585e",
        }}
      >
        i
      </p>
    </section>
  );
};
export const ReportsDatePicker = ({ _onChage }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
      const formattedStart = formatDate(start);
      const formattedEnd = formatDate(end);
      _onChage({ start: formattedStart, end: formattedEnd });
    }
  };
  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString().slice(-2);

    return `${day}/${month}/${year}`;
  };

  return (
    <section
      style={{
        position: "relative",
      }}
    >
      <p
        style={{
          paddingLeft: "10px",
          marginBottom: "5px",
          fontSize: "13px",
          color: "#9e9ea0",
          fontWeight: "500",
        }}
      >
        Date Range*
      </p>
      <button
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          minWidth: "175px",
          minHeight: "35px",
          backgroundColor: "#484b54",
          borderRadius: "5px",
          border: "none",
          paddingLeft: "8px",
          paddingRight: "5px",
          cursor: "pointer",
        }}
      >
        <DatePicker
          selectsRange
          startDate={startDate}
          endDate={endDate}
          onChange={handleDateChange}
          customInput={<CustomInput />}
          monthsShown={1}
          isClearable={true}
          dateFormat="dd/MM/yy"
        />
      </button>
    </section>
  );
};
const CustomInput = forwardRef(({ value, onClick }, ref) => {
  const formattedValue = value.replace(/ - /g, " ~ ");
  const [isHovered, setIsHovered] = useState(false);

  const hoverColor = "#1f2227";

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "transparent",
        border: "none",
        padding: "0px 0px 0px 0px",
        borderRadius: "4px",
        cursor: "pointer",
        // width:'250px',
        flex: 1,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <input
        style={{
          flex: 1,
          cursor: "pointer",
          backgroundColor: "transparent",
          border: "none",
          color: "white",
          padding: "0px",
          fontSize: "14.5px",
          fontWeight: 600,
          maxWidth: "140px",
        }}
        value={formattedValue}
        onClick={onClick}
        ref={ref}
        readOnly
      />
      <section
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "18px",
          overflow: "hidden",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="#2d2e34"
          viewBox="0 0 1024 1024"
          width="16px"
          height="16px"
          onClick={onClick}
          style={{ cursor: "pointer" }}
        >
          <path d="M940.218 107.055H730.764v-60.51H665.6v60.51H363.055v-60.51H297.89v60.51H83.78c-18.617 0-32.581 13.963-32.581 32.581v805.237c0 18.618 13.964 32.582 32.582 32.582h861.09c18.619 0 32.583-13.964 32.583-32.582V139.636c-4.655-18.618-18.619-32.581-37.237-32.581zm-642.327 65.163v60.51h65.164v-60.51h307.2v60.51h65.163v-60.51h176.873v204.8H116.364v-204.8H297.89zM116.364 912.291V442.18H912.29v470.11H116.364z"></path>
        </svg>
      </section>
    </div>
  );
});
export const Loader = () => {
  const defaultDotSize = 12;

  const [dots, setDots] = useState([
    { id: 0, startScale: 0, scale: defaultDotSize, marginLeft: "15px" },
    {
      id: 1,
      startScale: defaultDotSize,
      scale: defaultDotSize,
      marginLeft: "15px",
    },
    {
      id: 2,
      startScale: defaultDotSize,
      scale: defaultDotSize,
      marginLeft: "15px",
    },
    { id: 3, startScale: defaultDotSize, scale: 0, marginLeft: "-15px" },
  ]);

  useEffect(() => {
    const interval = setInterval(updateDots, 600); // Змінюємо точки кожні 1800мс
    return () => clearInterval(interval);
  }, []);

  const updateDots = () => {
    const milliseconds = new Date().getTime();

    setDots((dots) => {
      let newDots = [...dots]; // Clone the current dots array to avoid direct mutation
      newDots.pop(); // Step 1: Remove the last dot
      newDots.unshift({
        id: new Date().getTime(),
        startScale: 0,
        scale: defaultDotSize,
        marginLeft: "15px",
      });
      newDots[3] = {
        ...newDots[3],
        startScale: defaultDotSize,
        scale: 0,
        marginLeft: "-15px",
      };

      return newDots; // Return the modified array to update the state
    });
  };

  return (
    <article
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
        content: "none",
      }}
    >
      <section
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: "0px",
        }}
      >
        {dots.map((dot) => (
          <Doter key={dot.id} dot={dot} updateDots={updateDots} />
        ))}
      </section>
    </article>
  );
};
const Doter = ({ dot, updateDots }) => {
  const selectedColor = useSelector((state) => state.settings.selectedColor);
  const colors = useSelector((state) => state.settings.colors);
  const props = useSpring({
    from: { height: `${dot.startScale}px`, marginLeft: "15px" },
    to: { height: `${dot.scale}px`, marginLeft: dot.marginLeft },
    config: { duration: 400 },
    // onRest: () => {
    //     updateDots();
    // }
  });

  return (
    <animated.div
      style={{
        ...props,
        aspectRatio: "1/1",
        content: "none",
        borderRadius: "100%",
        backgroundColor: colors[selectedColor].fixed,
        backgroundImage: `linear-gradient(to right, ${colors[selectedColor].gradienStart}, ${colors[selectedColor].gradientEnd})`,
      }}
    ></animated.div>
  );
};
