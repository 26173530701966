import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSpring, animated } from 'react-spring';

export default function DropDown({ defaultTitle, dropDownItems, onCountrySelect, status, onOpen, fontColor}) {
    const [toggleArrow, setToggleArrow] = useState(false)
    const arrowAnimation = useSpring({
        transform: toggleArrow ? 'rotate(180deg)' : 'rotate(0deg)',
    })

    const [toggleDropDownWindow, setToggleDropDownWindow] = useState(status)
    useEffect(()=>{
        setToggleDropDownWindow(status)
        setToggleArrow(status)
    },[status])

    const dropDownWindowAnimation = useSpring({
        transform: toggleDropDownWindow ? 'translateY(0px)' : 'translateY(-20px)',
        opacity: toggleDropDownWindow ? '1' : '0',
        pointerEvents: toggleDropDownWindow ? 'auto' : 'none',
    })
    const dropDownInputAnimation = useSpring({
        opacity: toggleDropDownWindow ? '1' : '0',
        display: toggleDropDownWindow ? 'flex' : 'none',
    })
    const toggleDropDown = () => {
        onOpen()
        setToggleDropDownWindow(!toggleDropDownWindow)
    }
    const [items, setItems] = useState(dropDownItems)
    const toggleCountrySelecStatus = (id) => {
        const newItems = items.map((item) => {
            if (item.id === id) item.isSelect = !item.isSelect;
            return item
        })
        setItems(newItems)
        onCountrySelect && onCountrySelect(items ? items.filter((item) => item.isSelect) : [])
    }
    const selectAll = () => {
        const newItems = items.map((item) => {
            item.isSelect = true;
            return item
        })
        setItems(newItems)
    }
    const removeAll = () => {
        const newItems = items.map((item) => {
            item.isSelect = false;
            return item
        })
        setItems(newItems)
    }
    const [inputValue, setInputValue] = useState('');
    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    return (
        <section className={'dropDown'}style={{
            position: 'relative',
        }}>
            <button style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                minWidth: '220px',
                minHeight: '35px',
                backgroundColor: '#484b54',
                borderRadius: '5px',
                border: 'none',
                paddingLeft: '8px',
                paddingRight: '5px',
                cursor: 'pointer',
            }}>
                <animated.input style={{
                    ...dropDownInputAnimation,
                    color: '#bbbbbb',
                    fontWeight: '500',
                    position: 'absolute',
                    backgroundColor: 'transparent',
                    border: 'none',
                    fontSize: '14px',
                    paddingLeft: '8px',
                    top: '0px',
                    left: '0px',
                    height: '100%',
                    width: '90%'
                }}
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder='Type to Search'
                >
                </animated.input>
                <p style={{
                    fontSize: '14px',
                    color: !fontColor ? items ? items.filter((item) => item.isSelect).length > 0 ? 'white' : '#bbbbbb' : '#bbbbbb':fontColor,
                    fontWeight: '500'
                }}>{!toggleDropDownWindow ? items ? items.filter((item) => item.isSelect).length > 0 ? `Selected ${items.filter((item) => item.isSelect).length}` : defaultTitle : defaultTitle : ''}</p>
                <animated.span onClick={toggleDropDown} className="material-symbols-outlined" style={{ ...arrowAnimation, color: 'white' }}>expand_more</animated.span>
            </button>
            <animated.section style={{
                ...dropDownWindowAnimation,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '20px',

                position: 'absolute',
                top: '40px',
                left: '0px',
                width: '100%',
                height: '270px',
                backgroundColor: '#484b54',
                zIndex: 2
            }}>
                <section style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: '15px',
                    width: '100%',
                    paddingTop: '20px',
                }}>
                    <DropDownMenuTopButton title='Select All' onclick={selectAll} />
                    <div style={{ height: '100%', borderLeft: '1.5px solid #bbbbbb', content: 'none' }}></div>
                    <DropDownMenuTopButton title='Remove All' onclick={removeAll} border={true} />
                </section>

                <DropDownMenuList items={items ? items : []} selectedItems={items ? items.filter((item) => item.isSelect) : []} onSelect={toggleCountrySelecStatus} filterKey={inputValue} />
            </animated.section>
        </section>
    )
}
const DropDownMenuTopButton = ({ title, onclick }) => {
    return (
        <button onClick={onclick} style={{
            flex: 1,
            color: 'white',
            fontSize: '13px',
            fontWeight: '500',
            border: 'none',
            backgroundColor: 'transparent',
            cursor: 'pointer',
        }}>{title}</button>
    )
}
const DropDownMenuList = ({ items, selectedItems, onSelect, filterKey }) => {
    const filteredItems = filterKey
        ? items.filter(item => item.title.toLowerCase().includes(filterKey.toLowerCase()))
        : items;
    return (
        <section style={{
            position: 'relative',
            maxHeight: 'calc(100% - 36px)',
            minHeight:'calc(100% - 36px)',
            overflow: 'hidden',
            width: '100%',
        }}>

            <section style={{
                width: '100%',
                maxHeight: 'calc(100% - 36px)',
                minHeight:'calc(100% - 36px)',
                overflowY: 'auto'
            }}>
                {selectedItems.length > 0 && <DropDownSelectedMenuList selectedItems={selectedItems} onClick={onSelect} />}

                {filteredItems.map(item => (
                    <DropDownMenuListItem
                        key={item.id}
                        item={item}
                        onSelect={onSelect}
                    />
                ))}

                <section style={{
                    position: 'absolute',
                    bottom: '0px',
                    left: '0px',

                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',

                    height: '30px',
                    width: '100%',
                    backgroundColor: '#2f323b',
                }}>
                    <p style={{ fontSize: '13px', fontWeight: '500', color: '#afb0b0' }}>Selected {`${selectedItems.length}/${items.length}`}</p>
                </section>
            </section>

        </section>
    )
}
const DropDownMenuListItem = ({ item, onSelect }) => {
    const [isHovered, setIsHovered] = useState(false);
    const selectedColor = useSelector(state => state.settings.selectedColor);
    const colors = useSelector(state => state.settings.colors);

    return (
        <button onClick={() => onSelect(item.id)} style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '35px',
            minWidth: '95%',
            paddingLeft: '15px',
            backgroundColor: item.isSelect ? isHovered ? colors[selectedColor].gradienStart : colors[selectedColor].dropDownSelected : isHovered ? colors[selectedColor].dropDownSelected : 'transparent',
            border: 'none',

        }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}>

            <p style={{ width: '100%', fontSize: '15px', color: 'white', fontWeight: '500', textAlign: 'left' }}>{item.title}</p>
        </button>
    )
}
const DropDownSelectedMenuList = ({ selectedItems, onClick }) => {
    return (
        <section style={{
            position: 'relative',
            maxHeight: 'calc(100% - 36px)',
            overflow: 'hidden'
        }}>
            <section style={{
                width: '100%',
                maxHeight: 'calc(100% - 36px)',
                overflowY: 'auto'
            }}>
                {selectedItems.map(item => (
                    <DropDownSelectedMenuListItem key={item.id} item={item} onClick={onClick} />
                ))}

                <section style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    paddingLeft: '15px',

                    height: '30px',
                    width: '100%',
                    marginTop: '5px',
                    marginBottom: '15px',
                    borderBottom: '1px solid white'
                }}>
                    <p style={{ fontSize: '13px', fontWeight: '500', color: '#afb0b0' }}>Selected {`${selectedItems.length}`}</p>
                </section>
            </section>

        </section>
    )
}
const DropDownSelectedMenuListItem = ({ item, onClick }) => {
    const [isHovered, setIsHovered] = useState(false);
    const selectedColor = useSelector(state => state.settings.selectedColor);
    const colors = useSelector(state => state.settings.colors);

    return (
        <button onClick={() => onClick(item.id)} style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '40px',
            minWidth: '95%',
            paddingLeft: '15px',
            paddingRight: '15px',
            backgroundColor: isHovered ? '#3d404c' : 'transparent',
            border: 'none',

        }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}>

            <p style={{ width: '100%', fontSize: '15px', color: colors[selectedColor].dropDownSelected, fontWeight: '500', textAlign: 'left' }}>{item.title}</p>
            {isHovered && <span className="material-symbols-outlined" style={{ fontSize: '15px', color: 'white', fontWeight: 500 }}> close </span>}
        </button>
    )
}
