import React, { createContext, useContext, useState, useEffect, useRef } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
//Вікна
import Dashboard from './Dashboard';
import Login from './Login';
import Header from '../Header';
import Reports from './Reports';
import MainPage from '../MainPage';
//Сервіси
import { generateSettingItems } from '../../servises/settingItemGenerator';
// Локальний контекст
export const MainWindowContext = createContext();


const Inner = () => {
    return (
        <Router>
            <Routes>
                <Route path="/dashboard" element={<><Header /><Dashboard /></>} />
                <Route path="/reports" element={<><Header /><Reports /></>} />
                <Route path="" element={<><Header /><MainPage /></>} />
            </Routes>
        </Router>
    );
}

export default function MainWindow() {
    const testSettingsItems = generateSettingItems(5)
    const [contextGraphData, setContextGraphData] = useState(null)
    const [agregatedGraphData, setAgregatedGraphData] = useState({})
    const [selectedReportDates, setSelectedReportedsDates] = useState([new Date(), new Date()])
    const [calculatedTotalByAgregatesGraphData, setCalculatedTotalByAgregatesGraphData] = useState(null)

    const contextValue = {
        testSettingsItems, agregatedGraphData, selectedReportDates, calculatedTotalByAgregatesGraphData,contextGraphData,
        setAgregatedGraphData, setSelectedReportedsDates, setCalculatedTotalByAgregatesGraphData, setContextGraphData
    };

console.log(agregatedGraphData);
    return (
        <MainWindowContext.Provider value={contextValue}>
            <Inner />
        </MainWindowContext.Provider>
    );
}