import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSpring, animated } from 'react-spring';

export default function Loader() {

    return (
        <article style={{
            position: 'fixed',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            top: '0px',
            left: '0px',
            height: '100vh',
            width: '100vw',
            zIndex: 1000,
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            content: 'none',
        }}>
            <section style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px'
            }}>
                { }
                <Doter delay={100} />
                <Doter delay={200} />
                <Doter delay={300} />
            </section>
        </article>
    )
}


const Doter = ({ delay }) => {
    const [size, setSize] = useState(0);
    const selectedColor = useSelector(state => state.settings.selectedColor);
    const colors = useSelector(state => state.settings.colors);
    const props = useSpring({
        loop: true,
        from: { transform: 'scale(0)' },
        to: [
            { transform: 'scale(1)' },
            { transform: 'scale(0)' },
        ],
        config: { duration: 600 },
        delay: delay
    });

    return (
        <animated.div style={{
            ...props,
            height: '25px',
            aspectRatio: '1/1',
            content: 'none',
            borderRadius: '100%',
            // backgroundColor: '#ffbc00',
            backgroundColor: colors[selectedColor].fixed
        }}>
        </animated.div>
    );
};
