import React, { useEffect, useState } from 'react'
import { user, isUserAuthenticated, authenticateUser } from '../../servises/admin';
import { useSelector, useDispatch } from 'react-redux';
import { useSpring, animated } from 'react-spring';
import logo from '../../images/logo_200_60.svg'

export default function Login() {
    
    const selectedColor = useSelector(state => state.settings.selectedColor);
    const colors = useSelector(state => state.settings.colors);
    const [loadingLogoActive, setLoadingLogoActive] = useState(true)
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoadingLogoActive(false);
        }, 1500);
        return () => clearTimeout(timer);
    }, []);

    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(true);

    const [checkinUserDataStatus, setCheckinUserDataStatus] = useState(false)
    const [loginStatusString, setLoginStatusString] = useState('')

    const [messages, setMessages] = useState([]);

    const addMessage = (_type, _message) => {
        const currentId = new Date().getTime();
        setMessages([...messages, { id: currentId, _type, _message }]);
    };
    const removeMessage = (_id) => {
        setMessages(messages.filter(message => message.id !== _id));
    };
    const showMessage = (_type, _message) => {
        addMessage(_type, _message)
    }

    const [isUserNameValid, setIsUserNameValid] = useState(true)
    const [isPasswordValid, setIsPasswordValid] = useState(true)

    const validate = (username, password) => {
        if (username === 'admin') {

            const isUsernameValid = true
            const isPasswordValid = true

            return { isUsernameValid, isPasswordValid }
        }

        setIsUserNameValid(true)
        setIsPasswordValid(true)

        let isUsernameValid
        let isPasswordValid

        if (username) {
            isUsernameValid = true
        } else isUsernameValid = false
        setIsUserNameValid(isUsernameValid);

        if (password) {
            const passwordRegex = /^(?=.*[a-z])(?=.*\d).{6,}$/;
            isPasswordValid = passwordRegex.test(password);
        } else isPasswordValid = false
        setIsPasswordValid(isPasswordValid);

        return { isUsernameValid, isPasswordValid }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setCheckinUserDataStatus(true);
        setLoginStatusString('');

        const validateResult = validate(login, password)
        if (!validateResult.isUsernameValid || !validateResult.isPasswordValid) {
            setCheckinUserDataStatus(false)
            return
        }

        setTimeout(async () => {
            const isAuthenticated = await authenticateUser(login, password, rememberMe);
            if (isAuthenticated) {
                isUserAuthenticated()
                setLoginStatusString('');
                window.location.reload();
            } else {
                setLoginStatusString('');
                showMessage('error', 'Invalid credentials. Please try again')
            }
            setCheckinUserDataStatus(false);
        }, 1500);
    };

    return (
        <>
            {
                loadingLogoActive ?
                    <LoadingWindow /> :
                    <article style={{
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        paddingTop: '80px',

                        height: '100vh',
                        width: '100vw',
                        backgroundColor: '#2f323b',
                        overflow: 'hidden'
                    }}>
                        <section
                            style={{
                                position: 'absolute',
                                bottom: '0px',
                                width: '100%',
                                zIndex: 1,
                            }}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1081">
                                <defs>
                                    <linearGradient id="myGradient" x1="100%" y1="0%" x2="0%" y2="0%">
                                        <stop offset="0%" style={{ stopColor: colors[selectedColor].gradientEnd, stopOpacity: 1 }} />
                                        <stop offset="100%" style={{ stopColor: colors[selectedColor].gradienStart, stopOpacity: 1 }} />
                                    </linearGradient>

                                </defs>
                                <path className="cls-218" fill="url(#myGradient)" d="M318.49,719c110.09,2.9,188.38,88.2,311.81,61.53,129.21-27.92,194.25-371.3,481.1-481,222.84-85.19,701-90.29,940.71,377.75s65.53,1016-293.61,1045.94c-260.36,21.69-461.69-75.14-675.71-68.76-198.58,5.91-404,181.35-588.57,184.35C196.56,1843.63,48.27,1575-12.53,1364.32-70.47,1163.55-6.15,710.41,318.49,719Z" />
                            </svg>

                        </section>
                        {/* login section box */}
                        <section style={{ zIndex: 2, width: '470px', height: '550px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', padding: '25px', boxSizing: 'border-box', borderRadius: '10px', backgroundColor: '#272a32' }}>

                            <form onSubmit={handleSubmit} style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '100%',
                                gap: '42px',
                            }}>
                                <img src={logo} alt='logo' style={{
                                    height: '95%',
                                    marginTop: '10px',
                                }}></img>
                                <p style={{
                                    color: 'white',
                                    fontSize: '35px',
                                    fontWeight: '500',
                                    marginBottom: '8px',
                                }}>Welcome Back</p>
                                <div style={{
                                    position: 'relative',
                                    width: '85%'
                                }}>
                                    <p htmlFor="password" style={{ paddingLeft: '12px', display: 'block', marginBottom: '5px', fontSize: '12px', color: '#787879' }}>Username</p>
                                    <input
                                        type="text"
                                        id="login"
                                        value={login}
                                        placeholder='Enter your username'
                                        onChange={(e) => setLogin(e.target.value)}
                                        style={{ width: '100%', fontSize: '13px', fontWeight: '500', color: 'white', padding: '12px', borderRadius: '4px', backgroundColor: '#484b54', border: 'none' }}
                                    />
                                    {!isUserNameValid && (
                                        <CustomErrorText
                                            _text='This field is required'
                                            _boxStyles={{ bottom: '-22px' }}
                                        />
                                    )}

                                </div>
                                <div style={{
                                    position: 'relative',

                                    width: '85%',
                                    marginBottom: '25px',
                                }}>
                                    <p htmlFor="password" style={{ paddingLeft: '12px', marginBottom: '5px', display: 'block', fontSize: '12px', color: '#787879' }}>Password</p>
                                    <input
                                        type="password"
                                        id="password"
                                        value={password}
                                        placeholder='Enter your password'
                                        onChange={(e) => setPassword(e.target.value)}
                                        style={{
                                            width: '100%', fontSize: '13px', fontWeight: '500', color: 'white', padding: '12px', borderRadius: '4px', backgroundColor: '#484b54', border: 'none',
                                        }}
                                    />
                                    {!isPasswordValid && (
                                        <CustomErrorText
                                            _text={['This field is required', 'Must be at least 6 characters and must contain a lowercase character and a number']}
                                            _boxStyles={{ bottom: '-55px' }}
                                        />
                                    )}
                                </div>
                                <button
                                    type="submit"
                                    disabled={checkinUserDataStatus}
                                    style={{
                                        width: '85%',
                                        paddingTop: '12px',
                                        paddingBottom: '10px',
                                        backgroundImage: checkinUserDataStatus ?
                                            `linear-gradient(to right, ${colors[selectedColor].gradienStart}, ${colors[selectedColor].gradientEnd})` :
                                            `linear-gradient(to right, ${colors[selectedColor].gradienStart}, ${colors[selectedColor].gradientEnd})`,
                                        color: 'black',
                                        // color:'white',
                                        border: 'none',
                                        borderRadius: '4px',
                                        cursor: checkinUserDataStatus ? 'default' : 'pointer',
                                        fontSize: '15px'
                                    }}
                                >
                                    {!checkinUserDataStatus ? 'Login' : <LoadingAnimation />}
                                </button>
                                <ClickableText
                                    _text='Forgot Password? Please contact support'
                                    _hoverColor='#f5b50a'
                                    _styles={{
                                        position: 'relative',
                                        fontSize: '12px',
                                        fontWeight: '500',
                                        bottom: '23px',
                                    }}
                                />


                                <p>{loginStatusString}</p>
                            </form>
                        </section>
                        <section
                            style={{
                                position: 'absolute',
                                bottom: '60px',
                                right: '50px',

                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-start',
                                gap: '20px'
                            }}
                        >
                            {Array.isArray(messages) && messages.map(m => <Message key={m.id} _type={m._type} _message={m._message} _onRemove={() => removeMessage(m.id)} />)}
                        </section>
                    </article>
            }
        </>
    )
}
const CustomErrorText = ({ _textStyles, _boxStyles, _text }) => {

    const textStyles = {
        paddingLeft: '12px',
        display: 'block',
        fontSize: '13px',
        color: '#ff0000'
    }

    return (
        <div style={{
            position: 'absolute',
            pointerEvents: 'none',
            ..._boxStyles
        }}>
            {typeof _text === 'string' && (
                <p htmlFor="password" style={{ ...textStyles, ...textStyles }}>{_text}</p>
            )}
            {Array.isArray(_text) && (
                _text.map((t, i) => (
                    <p key={i} htmlFor="password" style={{ ...textStyles, ...textStyles }}>{t}</p>
                ))
            )}
        </div>
    )
}
const ClickableText = ({ _styles, _text, _defaultColor, _hoverColor, _onClick }) => {

    const [isMouseEnter, setIsMouseEnter] = useState(false)
    const defaultColor = _defaultColor ? _defaultColor : '#b4b4b5'


    return (
        <p
            onClick={_onClick ? _onClick : null}
            onMouseEnter={() => setIsMouseEnter(true)}
            onMouseLeave={() => setIsMouseEnter(false)}
            style={{
                color: _hoverColor && isMouseEnter ? _hoverColor : defaultColor,
                cursor: 'pointer',
                ..._styles
            }}
        >
            {_text}
        </p>
    )
}
const Message = ({ _type, _message, _onRemove }) => {
    const titles = {
        error: 'Oh no! Looks like something went wrong',
        success: ' Good news!'
    }
    const [isMassageHidding, setIsmassageHidding] = useState(false)
    const messageSectionStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: '12px',
        height: '95px',
        width: '265px',
        padding: '9px',
        backgroundColor: '#ff4146',
        zIndex: '1'
    }
    const fadeOutAndDown = useSpring({
        opacity: isMassageHidding ? 0 : 1,
        transform: isMassageHidding ? 'translateY(40px)' : 'translateY(0px)',
        config: { duration: 600 },
        onRest: () => _onRemove(),
    });
    return (
        <animated.section style={{
            ...fadeOutAndDown,
            ...messageSectionStyle,
        }}>
            <section
                style={{
                    flex: 1,
                    paddingTop: '3px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-end',
                    width: '100%',

                }}
            >
                <span
                    onClick={() => setIsmassageHidding(true)}
                    className="material-symbols-outlined"
                    style={{
                        fontSize: '15px',
                        color: 'white',
                        cursor: 'pointer'
                    }}
                >close</span>
            </section>
            <p
                style={{
                    color: 'white',
                    fontSize: '12.6px',
                    fontWeight: '500',
                    textAlign: 'left',
                    width: '100%'
                }}
            >
                {_type === 'error' && titles.error || _type === 'success' && titles.success}
            </p>
            <p
                style={{
                    color: 'white',
                    fontSize: '13.8px',
                    fontWeight: '800',
                    textAlign: 'left',
                    width: '100%'
                }}
            >
                {_message}
            </p>
        </animated.section>
    )
}
const LoadingAnimation = () => {
    const [doters, setDoters] = useState([])
    const instantiateDotersWithDelay = (_delay) => {
        let timeouts = [];

        for (let i = 0; i < 3; i++) {
            let timeout = setTimeout(() => {
                setDoters(d => [...d, <Dotter key={d.length} />]);
            }, _delay * i);

            timeouts.push(timeout);
        }
        return () => timeouts.forEach(clearTimeout);
    };

    useEffect(() => {
        setDoters([])
        let clearTimeouts = instantiateDotersWithDelay(300);

        return () => {
            clearTimeouts();
        };
    }, []);

    const Dotter = () => {
        const styles = useSpring({
            loop: true,
            to: [
                { transform: 'scale(1)' },
                { transform: 'scale(0)' }
            ],
            from: { transform: 'scale(0)' },
            config: { duration: 600 }
        });

        return (
            <animated.div
                style={{
                    ...styles,
                    height: '8px',
                    aspectRatio: '1/1',
                    backgroundColor: '#b4b4b7',
                    borderRadius: '100%',
                    content: 'none',
                }}
            ></animated.div>
        );
    };

    return (
        <section
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                paddingLeft: '45%',
                gap: '5px',

                height: '18px',
            }}
        >
            {Array.isArray(doters) && doters}
        </section>
    )
}

const LoadingWindow = () => {

    const selectedColor = useSelector(state => state.settings.selectedColor);
    const colors = useSelector(state => state.settings.colors);

    const Logo = () => {
        return (
            <div
                style={{
                    position: 'absolute',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '10px',

                    height: '220px',
                    aspectRatio: '1/1',
                    backgroundColor: '#272a32',
                    borderRadius: '50%',
                }}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width={20.643}
                    height={23.441}
                    viewBox="0 0 20.643 23.441"
                >
                    <defs>
                        <linearGradient
                            id="a"
                            x1={-0.735}
                            y1={475.43}
                            x2={19.494}
                            y2={455.2}
                            gradientTransform="matrix(1 0 0 -1 0 475.027)"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop offset={0} stopColor="#05fcac" />
                            <stop offset={1} stopColor="#03d5f7" />
                            {/* <stop offset={0} stopColor="#9544de" />
                            <stop offset={1} stopColor="#6d1aaa" /> */}
                        </linearGradient>
                        <linearGradient
                            id="b"
                            x1={-3.536}
                            y1={472.629}
                            x2={16.694}
                            y2={452.4}
                            xlinkHref="#a"
                        />
                    </defs>
                    <path
                        d="M11.22 3.883v4.28c0 .184.13.344.311.378 2.33.43 4.1 2.475 4.1 4.927s-1.77 4.497-4.1 4.927a.383.383 0 0 0-.31.378v4.28c0 .23.2.409.429.386 5.051-.516 8.993-4.783 8.993-9.971s-3.942-9.455-8.994-9.97a.389.389 0 0 0-.428.385Z"
                        style={{
                            fill: "url(#a)",
                        }}
                    />
                    <path
                        d="M9.422 18.773a.383.383 0 0 0-.31-.378 5.019 5.019 0 0 1-4.1-4.927V8.843c0-.214.173-.387.387-.387h3.637a.387.387 0 0 0 .386-.387V3.831a.387.387 0 0 0-.387-.387H5.03a.379.379 0 0 1-.351-.248A5.015 5.015 0 0 0 .414.001.386.386 0 0 0 0 .388v13.08c0 5.188 3.942 9.455 8.993 9.97a.389.389 0 0 0 .43-.385v-4.28Z"
                        style={{
                            fill: "url(#b)",
                        }}
                    />
                </svg>

                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    id="Layer_2"
                    width={144.0681}
                    height={23.2839}
                    viewBox="0 0 144.0681 23.2839"
                >
                    <defs>
                        <style>{".cls-1{fill:#fff;}"}</style>
                    </defs>
                    <g id="Layer_1-2">
                        <g>
                            <path
                                className="cls-1"
                                d="M8.5566,6.5111h-.0002c-.0002-.2112-.0172-.3913-.2711-.4194h-.0024c-.1231-.0006-.2446-.006-.3643-.0159v.0114c-.9263-.001-2.0373-.0097-2.9549,.0003h-.3859c-.2087,0-.396-.1261-.4757-.3191-.5939-1.4376-1.9208-2.4952-3.5152-2.706C.2766,3.0214,0,3.2627,0,3.5758v7.7234c0,.0334,.0035,.0647,.0085,.0951C.0067,13.2395,.0001,14.4847,.0001,14.4847c-.0227,4.913,3.3651,8.4694,8.1105,8.5476v.0035c.0319,0,.0631-.0002,.0934-.0012,.0155,0,.0306,.0011,.0461,.0012,.0005,0,.001-.0034,.0016-.0043,.1749-.013,.307-.0722,.305-.3431-.0002-.0202-.0003-.0373-.0004-.0557h.0005v-3.0799h-.0002c-.0002-.2112-.0172-.3913-.2711-.4194,0,0-.0014,0-.0024,0-2.493-.0124-4.3182-1.9716-4.3104-4.6179h-.0098c.0011-1.3133,.0074-2.6266-.0089-3.9396-.0053-.4298,.1292-.5688,.5582-.5554,.8994,.0281,2.4325,.0066,3.3328,.009,.1127,.0003,.2177-.0016,.2993-.0357,.0201,0,.0404,0,.0598-.0007,.0155,0,.0306,.0011,.0461,.0012,.0005,0,.001-.0034,.0016-.0043,.1749-.013,.307-.0722,.305-.3431-.0002-.0202-.0003-.0373-.0004-.0557h.0005v-3.0799h-.0003Z"
                            />
                            <path
                                className="cls-1"
                                d="M82.1366,8.168c-.031,.0329-.0865,.0454-.202,.0996V.7761c0-.1827-.0595-.3541-.1782-.5141-.1189-.1598-.3108-.2399-.5757-.2399h-2.3443c-.3657,0-.5964,.0778-.6924,.2331-.096,.1555-.1439,.3518-.1439,.5894V12.9748l.0037-.0008c.0009,.5531,.0021,1.1515,.0035,1.8171,.0012,.5335,.0372,1.0747,.1319,1.5989,.5839,3.2339,2.3472,5.5467,5.5455,6.4635,3.3066,.9479,6.3707,.3824,8.8066-2.221,2.4074-2.573,2.8292-7.0764,1.1309-10.3938-2.3818-4.6527-8.5328-5.2018-11.4855-2.0707h0Zm4.1923,11.1236c-2.5783,.0097-4.4052-1.8868-4.3989-4.5668,.0062-2.6169,1.9145-4.5973,4.4231-4.5902,2.4769,.007,4.3448,1.984,4.3442,4.5981-.0006,2.6236-1.8459,4.5493-4.3685,4.5588h0Z"
                            />
                            <path
                                className="cls-1"
                                d="M39,13.9252c-.5941-7.6405-8.4487-9.2784-12.9159-6.3897-4.3842,2.835-4.9655,9.8408-1.1434,13.5971,2.5919,2.5472,7.0101,3.0355,9.8575,.1135,.0711-.073,.161-.1311,.3335-.2672v1.1724c0,.1827,.0595,.3541,.1782,.5141,.1189,.1598,.3108,.2399,.5757,.2399h2.3443c.3657,0,.5964-.0778,.6924-.2331,.096-.1555,.1439-.3518,.1439-.5894v-2.5571c.0431-1.8781,.1025-3.4303-.0662-5.6005Zm-4.4459,3.1351c-1.4555,2.6588-5.2223,3.0147-7.1469,.6753-.6565-.7979-1.0156-1.8477-1.0206-2.9827-.0113-2.6056,1.7777-4.5848,4.1927-4.6162,4.2584-.0554,5.3893,4.3398,3.9748,6.9237h0Z"
                            />
                            <path
                                className="cls-1"
                                d="M105.2479,6.2255c-4.9553-.0099-8.4187,3.4754-8.4298,8.4831-.011,4.9802,3.4504,8.5251,8.3327,8.5337,4.8489,.0085,8.3243-3.5162,8.3471-8.4657,.0229-4.9613-3.4312-8.5415-8.25-8.5511h0Zm-.0934,13.0662c-2.5545-.0079-4.3911-1.9422-4.3731-4.6057,.0179-2.6553,1.8773-4.5643,4.4333-4.5517,2.493,.0124,4.3185,1.9653,4.3108,4.6115-.0077,2.6411-1.8467,4.5537-4.371,4.5458h0Z"
                            />
                            <path
                                className="cls-1"
                                d="M57.443,16.2989c-.9736,.0025-1.9491,.0089-2.9242-.0087-.3575-.0065-.5373,.0906-.6662,.4461-.3639,1.0035-1.0754,1.7039-2.0471,2.1382-2.9899,1.3363-6.0883-.5236-6.3714-3.8229-.1868-2.177,1.1788-4.2178,3.1909-4.7523,2.1805-.5793,4.5977,.4118,5.2893,2.4981,.0418,.1262,.22,.2932,.3373,.2953,1.0414,.018,2.0832,.0152,3.1513,.0138,.3415-.0004,.6037-.3076,.5448-.6439-.498-2.8436-3.2109-6.3147-8.2864-6.2447-4.8302,.0667-8.2811,3.7428-8.1957,8.8133,.0823,4.8876,3.8069,8.3603,8.8018,8.2064,3.8628-.119,7.4389-3.1027,7.7266-6.3335,.0289-.3246-.2251-.6057-.551-.6049v-.0003Z"
                            />
                            <path
                                className="cls-1"
                                d="M135.9243,16.3498c-1.8124,1.1719-2.0527,4.0681-.4727,5.6209,1.0714,1.053,2.8979,1.2548,4.075,.0469,.0304-.0312,.0682-.055,.1442-.1153v.794h1.6083c0-1.2544,.0793-2.4865-.0154-3.705-.2456-3.1585-3.4926-3.8356-5.3393-2.6414h-.0001Zm3.5014,3.9374c-.6017,1.0991-2.1588,1.2462-2.9545,.2792-.2714-.3299-.4198-.7638-.4219-1.233-.0047-1.0771,.7349-1.8953,1.7332-1.9083,1.7604-.0229,2.2278,1.794,1.6431,2.8622h.0001Z"
                            />
                            <circle className="cls-1" cx={143.2465} cy={16.7711} r={0.8216} />
                            <path
                                className="cls-1"
                                d="M132.8852,21.2099c-.4537,0-.8215,.3678-.8215,.8216s.3678,.8216,.8215,.8216,.8216-.3678,.8216-.8216-.3678-.8216-.8216-.8216Z"
                            />
                            <rect
                                className="cls-1"
                                x={142.4268}
                                y={18.3057}
                                width={1.6394}
                                height={4.3906}
                            />
                            <path
                                className="cls-1"
                                d="M76.7527,21.8719h.0273l-7.3072-9.0208,6.1556-6.4296c.0731-.0913,.1233-.1736,.1509-.2469,.0273-.0729,.0411-.1553,.0411-.2467,0-.1371-.0504-.2469-.1509-.3291-.1007-.0822-.2515-.1233-.4525-.1233h-3.1395c-.1736,0-.3221,.0322-.4455,.096-.1233,.064-.2446,.1555-.3632,.2742l-6.0323,6.5256V.754c0-.1827-.0595-.3541-.1782-.5141-.1189-.1598-.3108-.2399-.5757-.2399h-2.3443c-.3657,0-.5964,.0778-.6924,.2331-.096,.1555-.1439,.3518-.1439,.5894V22.1324c0,.0549,.0137,.1257,.0411,.2126,.0273,.0869,.073,.1712,.137,.2537,.0639,.0822,.1417,.1507,.2331,.2056,.0912,.0549,.2057,.0822,.3427,.0822h2.4267c.146,0,.2695-.0273,.3701-.0822,.1005-.0549,.1804-.1233,.2399-.2056,.0593-.0824,.1028-.1714,.1302-.2674s.0411-.185,.0411-.2672v-4.9355l1.6451-1.7136,5.3056,6.8959c.2469,.3838,.5025,.5758,.7678,.5758h3.4959c.1827,0,.3198-.0273,.4111-.0822s.1371-.1598,.1371-.3153c0-.0731-.0206-.1689-.0617-.2878-.0411-.1187-.1119-.2285-.2124-.3291l.0003-.0004Z"
                            />
                            <path
                                className="cls-1"
                                d="M20.464,6.232c-.4944,.0383-.971,.0735-1.4437,.1373-4.0732,.5499-6.8326,3.3526-7.0686,7.4527-.1616,2.8071-.071,2.772-.0723,5.2937h-.0241v3.0356c0,.1827,.0595,.3541,.1782,.5141,.1189,.1598,.3108,.2399,.5757,.2399h2.3443c.3657,0,.5964-.0778,.6924-.2331,.096-.1555,.1439-.3518,.1439-.5894v-2.7641c.0154-.0505,.0261-.103,.0261-.1585,0-.0501,0-.0999,.0002-.1496,.006-2.6245-.0325-2.0726,.0334-4.6956,.0465-1.85,1.0038-3.1552,2.7687-3.7596,.6321-.2165,1.3033-.3187,1.9914-.4487,.2593-.049,.447-.2763,.447-.5401v-2.7871c0-.3203-.2735-.5723-.5928-.5476h.0002Z"
                            />
                            <path
                                className="cls-1"
                                d="M130.1519,22.3533c-.0055-.039-.0187-.0779-.0449-.1135-1.8808-2.5551-3.8036-5.1626-5.6386-7.6537,1.835-2.4911,3.7577-5.0986,5.6386-7.6537,.1216-.1652,.0046-.3987-.2005-.4h-.0039c-.0076,0-2.6296-.0211-3.9309,.0136-.236,.0063-.5461,.1647-.6896,.3522-.8499,1.1096-1.6804,2.2354-2.5323,3.3892-.1417,.1655-.2771,.3333-.4028,.5045-.1171,.1595-.2495,.3078-.4099,.5044-1.1183-1.5094-2.1799-2.9642-3.2748-4.3937-.1432-.187-.4525-.345-.6879-.3513-1.3009-.0347-3.9244-.0136-3.932-.0136h-.004c-.2051,.0013-.3221,.2347-.2005,.4,1.8922,2.5706,3.7762,5.1444,5.6206,7.6486-1.8444,2.5041-3.92,5.368-5.8122,7.9386-.1216,.1652-.0046,.3986,.2005,.3999h.004c.0076,0,2.6311,.0212,3.932-.0136,.2354-.0063,.5447-.1643,.6879-.3513,1.0949-1.4295,2.3481-3.1743,3.4665-4.6837,.0982,.1203,.1849,.222,.265,.3198,1.1069,1.4944,2.1593,2.9358,3.2439,4.352,.1436,.1874,.4536,.3459,.6896,.3522,1.3013,.0347,3.9233,.0136,3.9309,.0136h.004c.2051-.0013,.322-.2348,.2005-.4-.0395-.0536-.0796-.1067-.119-.1602l-.0002-.0003Z"
                            />
                        </g>
                    </g>
                </svg>

            </div>
        )
    }
    const GradientCircle = ({ radius, color1, color2 }) => {
        const circleStyle = {
            height: `${radius}px`,
            width: `${radius}px`,
            aspectRatio: '1 / 1',
            background: `conic-gradient(${color1} 0%, ${color2} 50%, ${color1} 100%)`,
            borderRadius: '50%',
            filter: 'blur(8px)'
        };
        const shadowStyle = {
            background: `conic-gradient(${color1} 0%, ${color2} 50%, ${color1} 100%)`,
            borderRadius: '50%',
            height: `${radius + 20}px`,
            width: `${radius + 20}px`,
            filter: 'blur(20px)',
            position: 'absolute',
            zIndex: '-1',
        };
        const rotateAnimation = useSpring({
            from: { transform: 'rotate(0deg)' },
            to: { transform: 'rotate(360deg)' },
            config: { duration: 600 },
            loop: true,
        });

        return (
            <animated.div style={{
                ...rotateAnimation,
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
            >
                <div style={shadowStyle}></div>
                <div style={circleStyle}></div>
            </animated.div>
        );
    };

    return (
        <article
            style={{
                position: 'fixed',
                top: '0px',
                height: '100vh',
                width: '100vw',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#272a32',
                // backgroundColor:'red',
                zIndex: 3
            }}
        >
            <GradientCircle radius={230} color1={colors[selectedColor].gradienStart} color2={colors[selectedColor].gradientEnd} />
            <Logo />
        </article>
    )
}