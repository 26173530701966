export let api_url = '';
export const user = {}

export const setOriginDomain = (domain) => {
    api_url = `${domain}/admin.php`;//`${domain}/admin.php`;
};
export const isUserAuthenticated = () => {
    // return true
    const userJson = localStorage.getItem('user');

    if (userJson !== null) {
        Object.assign(user, JSON.parse(userJson));
        return true;
    } else {
        Object.keys(user).forEach(key => delete user[key]);
        return false;
    }
};

export const authenticateUser = async (login, password, isRemember) => {
    try {
        const queryParams = new URLSearchParams({
            action: 'authenticate',
            login: login,
            password: password
        });
        const urlWithParams = `${api_url}?${queryParams.toString()}`;

        const response = await fetch(urlWithParams);
        const data = await response.json();
        console.log(data);

        if (response.ok) {
            if (isRemember) {
                localStorage.setItem('user', JSON.stringify({
                    user: data.user || '',
                    password: data.password || '',
                    token: data.token || ''
                }));
            }
            return true;
        } else {
            throw new Error(data.message || 'Аутентифікація невдала');
        }

    } catch (error) {
        console.error('Помилка аутентифікації:', error);
        return false;
    }
};
export const logout = () => {
    localStorage.removeItem('user');
    window.location.reload();
};
export const getUsers = async() => {
    try {
        const queryParams = new URLSearchParams({
            action: 'getUsers',
            token: user.token
        });
        const urlWithParams = `${api_url}?${queryParams.toString()}`;

        const response = await fetch(urlWithParams);
        const data = await response.json();
        console.log(data);

        if (response.ok) {
            return data
        } else {
            throw new Error(data.message || 'Невдалось отримати користувачів');
        }

    } catch (error) {
        console.error('Помилка:', error);
        return [];
    }
}
export const changeToken = async(newToken) => {
    try {
        const queryParams = new URLSearchParams({
            action: 'changeToken',
            token: user.token,
            newToken
        });
        const urlWithParams = `${api_url}?${queryParams.toString()}`;

        const response = await fetch(urlWithParams);
        const data = await response.json();
        console.log(data);

        if (response.ok) {
            localStorage.setItem('user', JSON.stringify({
               ...user,
               token: newToken
            }));
            return true;
        } else {
            throw new Error(data.message || 'Зміна токена невлада');
        }

    } catch (error) {
        console.error('Помилка:', error);
        return false;
    }
}
export const changePassword = async(newPassword) => {
    try {
        const queryParams = new URLSearchParams({
            action: 'changePassword',
            token: user.token,
            newPassword
        });
        const urlWithParams = `${api_url}?${queryParams.toString()}`;

        const response = await fetch(urlWithParams);
        const data = await response.json();
        console.log(data);

        if (response.ok) {
            localStorage.setItem('user', JSON.stringify({
               ...user,
               password: newPassword
            }));
            return true;
        } else {
            throw new Error(data.message || 'Зміна пароля невлада');
        }

    } catch (error) {
        console.error('Помилка:', error);
        return false;
    }
}
export const removeUser = async(userLogin) => {
    try {
        const queryParams = new URLSearchParams({
            action: 'removeUser',
            token: user.token,
            userLogin
        });
        const urlWithParams = `${api_url}?${queryParams.toString()}`;

        const response = await fetch(urlWithParams);
        const data = await response.json();
        console.log(data);

        if (response.ok) {
            return true;
        } else {
            throw new Error(data.message || 'Видалення користувача невдале');
        }

    } catch (error) {
        console.error('Помилка:', error);
        return false;
    }
}
export const addUser = async(userLogin, userPassword) => {
    try {
        const queryParams = new URLSearchParams({
            action: 'addUser',
            token: user.token,
            userLogin,
            userPassword
        });
        const urlWithParams = `${api_url}?${queryParams.toString()}`;

        const response = await fetch(urlWithParams);
        const data = await response.json();
        console.log(data);

        if (response.ok) {
            return true;
        } else {
            throw new Error(data.message || 'Додавання користувача невдале');
        }

    } catch (error) {
        console.error('Помилка:', error);
        return false;
    }
}