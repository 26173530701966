import React, { useState, useEffect, forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from 'react-redux';
import { setSelectedDatesAction } from '../store/settingsReducer';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import enGB from 'date-fns/locale/en-GB';

registerLocale('en-GB', enGB);
setDefaultLocale('en-GB');

const CustomInput = forwardRef(({ value, onClick }, ref) => {
  const formattedValue = value.replace(/ - /g, ' ~ ');
  const [isHovered, setIsHovered] = useState(false);

  const hoverColor = '#1f2227'

  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: !isHovered ? 'transparent' : hoverColor,
      border: 'none',
      padding: '15px',
      borderRadius: '4px',
      width:'250px',
      flex: 1,
    }}

      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <input
        style={{
          flex: 1,
          backgroundColor: 'transparent',
          border: 'none',
          color: 'white',
          padding:'0px',
          fontSize: '11px',
          fontWeight:600,
          maxWidth: '115px',
          // paddingRight:'10px'
          // gap:'10px'
        }}
        value={formattedValue}
        onClick={onClick}
        ref={ref}
        readOnly
      />
      <section
      style={{
        display:'flex', flexDirection:'row',
        justifyContent:'center',alignItems:'center',
        width: '18px',
        height:'18px',
        // border:'1px solid red',
        overflow: 'hidden',
      }}
      >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" width="16px" height="16px" onClick={onClick} style={{ cursor: 'pointer' }}>
        <path d="M940.218 107.055H730.764v-60.51H665.6v60.51H363.055v-60.51H297.89v60.51H83.78c-18.617 0-32.581 13.963-32.581 32.581v805.237c0 18.618 13.964 32.582 32.582 32.582h861.09c18.619 0 32.583-13.964 32.583-32.582V139.636c-4.655-18.618-18.619-32.581-37.237-32.581zm-642.327 65.163v60.51h65.164v-60.51h307.2v60.51h65.163v-60.51h176.873v204.8H116.364v-204.8H297.89zM116.364 912.291V442.18H912.29v470.11H116.364z"></path>
      </svg>
      </section>
    </div>
  );
});

const DateRangePicker = ({filterDatesSelect, changeFilterDataRange}) => {
  const [startDate, setStartDate] = useState(filterDatesSelect[0] ? filterDatesSelect[0] : new Date());
  const [endDate, setEndDate] = useState(filterDatesSelect[0] ? filterDatesSelect[1] : new Date());
  const dispatch = useDispatch();

  const convertStrDateToDateObject = (dateString) => {
    const dateParts = dateString.split('/');

    const day = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1;
    const year = parseInt(dateParts[2], 10) + 2000;

    const dateObject = new Date(year, month, day);
    return dateObject
  }

  useEffect(() => {
    if (!filterDatesSelect) return

    if (filterDatesSelect.length >= 2) {
      if (typeof (filterDatesSelect[0]) == 'string') {
        setStartDate(convertStrDateToDateObject(filterDatesSelect[0]))
        setEndDate(convertStrDateToDateObject(filterDatesSelect[filterDatesSelect.length - 1]))
      } else {
        setStartDate(filterDatesSelect[0])
        setEndDate(filterDatesSelect[filterDatesSelect.length - 1])
      }
    } else {
      if (typeof (filterDatesSelect[0]) == 'string') {
        setStartDate(convertStrDateToDateObject(filterDatesSelect[0]))
        setEndDate(convertStrDateToDateObject(filterDatesSelect[0]))
      } else {
        setStartDate(filterDatesSelect[0])
        setEndDate(filterDatesSelect[0])
      }
    }
  }, [filterDatesSelect])
  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
      const formattedStart = formatDate(start);
      const formattedEnd = formatDate(end);
      changeFilterDataRange('period',[start,end]);
      dispatch(setSelectedDatesAction([formattedStart, formattedEnd]));
    }
  }
  const formatDate = (date) => {
    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
  };

  return (

    <DatePicker
      selectsRange
      startDate={startDate}
      endDate={endDate}
      onChange={handleDateChange}
      customInput={<CustomInput />}
      monthsShown={2}
      isClearable={true}
      dateFormat="dd/MM/yy"
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        decreaseYear,
        increaseYear,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
        prevYearButtonDisabled,
        nextYearButtonDisabled,
      }) => (
        <div className="custom-header" style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent:'space-between',
          alignItems: 'center'
        }}>
          <section>
            <button onClick={decreaseYear} disabled={prevYearButtonDisabled} style={{
              backgroundColor: 'transparent',
              color: '#73869c',
              border: 'none'
            }}>
              <span class="material-symbols-outlined">
                keyboard_double_arrow_left
              </span>
            </button>
            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled} style={{ ...monthNavigateButtonStyle }}>
              <span class="material-symbols-outlined">
                navigate_before
              </span>
            </button>
          </section>
          <span style={{
            color: '#73869c',
            fontSize: '14px',
            fontFamily: 'Montserrat',
            fontWeight: '700',
            letterSpacing: '-0.5px !important',

          }}>{date.toLocaleString('en-GB', { month: 'short' })} {date.getFullYear()}</span>
          <section>
            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled} style={{ ...monthNavigateButtonStyle }}>
              <span class="material-symbols-outlined">
                navigate_next
              </span>
            </button>
            <button onClick={increaseYear} disabled={nextYearButtonDisabled} style={{ ...monthNavigateButtonStyle }}>
              <span class="material-symbols-outlined">
                keyboard_double_arrow_right
              </span>
            </button>
          </section>
        </div>
      )}
    />


  );
};

export default DateRangePicker;


const monthNavigateButtonStyle = {
  backgroundColor: 'transparent',
  color: '#73869c',
  border: 'none',
  fontWeight: '100'
}
