import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';


// import logo from '../images/logo.png'
import logo from '../images/logo_200_60.svg'

export default function Header() {
    const selectedColor = useSelector(state => state.settings.selectedColor);
    const colors = useSelector(state => state.settings.colors);
    const navigate = useNavigate();
    return (
        <header style={{
            position: 'fixed',
            top: '0px',
            left: '0px',

            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',

            height: '75px',
            width: '100%',
            backgroundColor: colors[selectedColor].headerBgColor ? colors[selectedColor].headerBgColor : '#23242c',

            zIndex: 100,
        }}>

            <MenuSection />
            <UserInfoSection />
        </header>
    )
}

const MenuSection = () => {
    const location = useLocation();
    const isActive = location.pathname === '/reports';
    const settings = useSelector(state => state.settings);
    const navigate = useNavigate();

    return (
        <section style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: '10px',

            height: '100%',
            minWidth: '500px',
        }}>
            <section style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                height: '100%',
                gap: '8px',

                marginLeft: '20px',
            }}>
                <MenuLines />
                <img
                    onClick={() => navigate('/')}
                    src={logo} alt='logo' style={{
                        height: '60%',
                        cursor: 'pointer',
                    }}></img>
            </section>
            <section style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
            }}>
                <MenuSectionItem title='LEADS' _onClick={() => navigate('/')} _isActive={location.pathname === '/'}>
                    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 16.13 16.13" width="25px" height="25px">
                        <path fill="currentColor" d="M5.33,5.46a.24.24,0,0,1,.07.21L5.19,6.89a1.07,1.07,0,0,0,.24.89A1.12,1.12,0,0,0,6.79,8l1.09-.57a.28.28,0,0,1,.22,0L9.19,8a1.08,1.08,0,0,0,.52.13,1.09,1.09,0,0,0,1.08-1.28l-.21-1.22a.24.24,0,0,1,.07-.21l.88-.86a1.09,1.09,0,0,0-.61-1.87L9.7,2.55a.24.24,0,0,1-.18-.13L9,1.31a1.11,1.11,0,0,0-2,0L6.46,2.42a.26.26,0,0,1-.18.13l-1.22.18A1.09,1.09,0,0,0,4.45,4.6ZM10.93,4l-.88.85a1.12,1.12,0,0,0-.32,1L9.94,7a.18.18,0,0,1,0,.19.23.23,0,0,1-.3.06L8.5,6.71a1.14,1.14,0,0,0-1,0l-1.09.57a.23.23,0,0,1-.3-.06A.18.18,0,0,1,6,7l.21-1.21a1.12,1.12,0,0,0-.32-1L5.05,4A.24.24,0,0,1,5,3.74a.22.22,0,0,1,.19-.16L6.4,3.4a1.1,1.1,0,0,0,.83-.6l.54-1.11a.25.25,0,0,1,.44,0L8.75,2.8a1.1,1.1,0,0,0,.83.6l1.22.18a.22.22,0,0,1,.19.16A.24.24,0,0,1,10.93,4Z" />
                        <path fill="currentColor" d="M9.83,9.31A5.06,5.06,0,0,0,6.44,9.2c-2.91.78-4.38,1.61-4.36,2.47v3.91a.43.43,0,0,0,.43.43.43.43,0,0,0,.43-.43V11.66s.12-.23.67-.52l.65-.29a8.36,8.36,0,0,1,1-.38l2.34,4a.43.43,0,0,0,.38.22.41.41,0,0,0,.37-.22l2.3-4c.34.13.57.22.83.34l.65.34c.79.44.95.7.95.77v3.63a.43.43,0,0,0,.43.43.43.43,0,0,0,.43-.43V12C13.92,10.88,11.7,9.92,9.83,9.31Zm0,.89L8.34,12.69V9.84a6.14,6.14,0,0,1,1.22.28ZM7.47,9.85v2.82L6.08,10.19,6.66,10A6.8,6.8,0,0,1,7.47,9.85Z" />
                        <path fill="currentColor" d="M12.69,2.59a.37.37,0,0,0,.23.23.32.32,0,0,0,.16,0,.33.33,0,0,0,.17,0l2.47-1A.42.42,0,0,0,16,1.56a.36.36,0,0,0,0-.32A.42.42,0,0,0,15.72,1a.47.47,0,0,0-.33,0L12.92,2a.43.43,0,0,0-.23.56Z" />
                        <path fill="currentColor" d="M15.72,6.42l-2.47-1a.47.47,0,0,0-.33,0,.42.42,0,0,0-.23.24.38.38,0,0,0,0,.33.43.43,0,0,0,.23.23l2.47,1a.35.35,0,0,0,.17,0A.42.42,0,0,0,16,7a.38.38,0,0,0,0-.33A.4.4,0,0,0,15.72,6.42Z" />
                        <path fill="currentColor" d="M15.54,3.7h-2.4a.44.44,0,1,0,0,.88h2.4a.44.44,0,1,0,0-.88Z" />
                        <path fill="currentColor" d="M.41,1.8l2.47,1a.32.32,0,0,0,.16,0,.35.35,0,0,0,.17,0,.4.4,0,0,0,.23-.23h0A.43.43,0,0,0,3.21,2L.74,1A.47.47,0,0,0,.41,1a.47.47,0,0,0-.24.24.44.44,0,0,0,0,.32A.47.47,0,0,0,.41,1.8Z" />
                        <path fill="currentColor" d="M3.44,6a.42.42,0,0,0,0-.33.45.45,0,0,0-.23-.24.47.47,0,0,0-.33,0l-2.47,1a.45.45,0,0,0-.24.23A.47.47,0,0,0,.17,7a.45.45,0,0,0,.4.27.35.35,0,0,0,.17,0l2.47-1A.47.47,0,0,0,3.44,6Z" />
                        <path fill="currentColor" d="M3,3.7H.59a.44.44,0,1,0,0,.88H3A.44.44,0,0,0,3,3.7Z" />

                    </svg>
                </MenuSectionItem>
                {/* <MenuSectionItem title='MARKETPLACE'>
                    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 16.13 16.13" width="25px" height="25px">
                        <path fill="currentColor" d="M9.12,14.68h0a1.43,1.43,0,0,1,0-.21,1.58,1.58,0,0,0,.41-.72c.05-.24.15-.74.18-1a2,2,0,0,0,0-.67,1.66,1.66,0,0,0-1.72-1.32,1.66,1.66,0,0,0-1.72,1.35,2.16,2.16,0,0,0,0,.63c0,.26.12.75.17,1a1.66,1.66,0,0,0,.41.73,1.31,1.31,0,0,1,0,.2h0l-1.33.69a.37.37,0,0,0-.17.2.36.36,0,0,0,0,.27.35.35,0,0,0,.47.14l1.11-.58A1.69,1.69,0,0,0,8,15.74a1.54,1.54,0,0,0,1-.34l1.15.59.16,0a.34.34,0,0,0,.31-.19.33.33,0,0,0,0-.26.34.34,0,0,0-.17-.2Zm-1.65.2a1.54,1.54,0,0,0,1,0s0,0,0,.08a1,1,0,0,1-.46.09,1.1,1.1,0,0,1-.51-.1S7.47,14.91,7.47,14.88ZM8.6,11.82l-.1.06a2.8,2.8,0,0,1-1.59.33v0a1,1,0,0,1,1-.72,1.15,1.15,0,0,1,.84.33A.37.37,0,0,0,8.6,11.82Zm0,.81a.84.84,0,0,0,.39.35,6,6,0,0,1-.12.59.9.9,0,0,1-1.75,0c0-.17-.1-.45-.13-.68A4.6,4.6,0,0,0,8.55,12.63Z" />
                        <path fill="currentColor" d="M5.29,12.64a1.32,1.32,0,0,1,0-.27,1.62,1.62,0,0,1,.3,0h.11v-.11a3.11,3.11,0,0,1,0-.33s.06-.25.06-.25h0a3.29,3.29,0,0,1,.16-.33L6,11.26a1.67,1.67,0,0,1,.39-.36l.06,0,0-.07a9.71,9.71,0,0,1-.18-1.65V9s0,0,0,0,0-.21,0-.32A2.28,2.28,0,0,0,3.86,6.83,2.28,2.28,0,0,0,1.49,8.61a2.9,2.9,0,0,0,0,.74,6.5,6.5,0,0,1-.72,2.87.34.34,0,0,0,.05.43.36.36,0,0,0,.43,0,2.31,2.31,0,0,1,1.22-.33,1.23,1.23,0,0,1,0,.27h0s-1.4.58-1.81.79a.34.34,0,1,0,.31.61c.27-.13,1.12-.49,1.54-.67.34.41.85,1.16,1.13,1.57l0,0a.34.34,0,0,0,.29.16A.35.35,0,0,0,4.14,15l0,0c.28-.41.87-1.37,1.21-1.78C5.37,13.17,5.3,12.64,5.29,12.64ZM3.86,12A1.51,1.51,0,0,1,2.4,10.84c0-.18-.1-.48-.15-.78a3,3,0,0,0,1.8-.64,1.65,1.65,0,0,0,1.23.5h.21c-.06.33-.13.71-.18.92A1.5,1.5,0,0,1,3.86,12ZM2.17,8.71A1.6,1.6,0,0,1,3.86,7.52,1.59,1.59,0,0,1,5.54,8.74c0,.11,0,.2,0,.29V9.2a1.07,1.07,0,0,1-1.08-.34,1.85,1.85,0,0,0,.2-.57A.34.34,0,0,0,4.64,8a.38.38,0,0,0-.22-.15.35.35,0,0,0-.26.06A.33.33,0,0,0,4,8.16a1.5,1.5,0,0,1-.19.47,2,2,0,0,1-1.68.74h0c0-.29,0-.48,0-.53h0Zm1,3.83a2.07,2.07,0,0,0,1.47,0,1.65,1.65,0,0,0,.1.5c-.26.32-.59.77-.83,1.14-.35-.5-.63-.89-.84-1.15A2,2,0,0,0,3.12,12.54ZM2,11.69l-.32.06c0-.13.09-.27.12-.41A2.43,2.43,0,0,0,2,11.69Z" />
                        <path fill="currentColor" d="M15.48,13.49l-2-1a.27.27,0,0,0-.12,0,1.72,1.72,0,0,1,0-.49h0a2.33,2.33,0,0,0,.58-1c.08-.34.22-1.06.27-1.43a2.81,2.81,0,0,0,0-.94,2.27,2.27,0,0,0-2.36-1.79A2.28,2.28,0,0,0,9.45,8.53v.06a2.79,2.79,0,0,0,0,.88A11,11,0,0,0,9.7,10.9a2.16,2.16,0,0,0,.59,1h0c0,.05-.06.63-.1,1a.39.39,0,0,0,.15.35,2.66,2.66,0,0,0,1.51.48,2.16,2.16,0,0,0,1.53-.53l1.79.91a.4.4,0,0,0,.15,0,.34.34,0,0,0,.16-.65ZM11,12.41a2.08,2.08,0,0,0,1.66,0,1.75,1.75,0,0,0,.08.41,1.5,1.5,0,0,1-.88.22,1.68,1.68,0,0,1-.94-.23A3.53,3.53,0,0,0,11,12.41Zm1.87-4.15a1.11,1.11,0,0,0-.33.21.93.93,0,0,1-.72.2.87.87,0,0,1-.7-.2,1.45,1.45,0,0,0-.33-.21.59.59,0,0,0-.23,0,.62.62,0,0,0-.32.09,1.72,1.72,0,0,1,1.59-.87,1.71,1.71,0,0,1,1.58.87A.61.61,0,0,0,12.86,8.26ZM10.2,9.77a2.36,2.36,0,0,1,.35-.86l.14.1a1.48,1.48,0,0,0,1.07.35h.05A1.6,1.6,0,0,0,13,9l.13-.09a2.41,2.41,0,0,1,.35.84.15.15,0,0,0,0,.07c-.06.33-.13.7-.18.91a1.5,1.5,0,0,1-1.45,1.15,1.49,1.49,0,0,1-1.45-1.14c-.06-.25-.13-.62-.18-.92C10.19,9.81,10.2,9.79,10.2,9.77Z" />
                        <path fill="currentColor" d="M13.77.11H2.35A2.24,2.24,0,0,0,.11,2.35v.76A2.26,2.26,0,0,0,2.24,5.36l.64.83a.35.35,0,0,0,.27.13.35.35,0,0,0,.18-.05l1.5-.91H6.94l.88.86a.35.35,0,0,0,.49,0l.88-.86h2.44l1.13.89a.35.35,0,0,0,.49-.06l.63-.83A2.27,2.27,0,0,0,16,3.11V2.35A2.24,2.24,0,0,0,13.77.11Zm1.56,2.24v.76a1.56,1.56,0,0,1-1.56,1.56h-.06a.34.34,0,0,0-.27.13l-.53.69L12,4.74a.31.31,0,0,0-.21-.07H9.05a.35.35,0,0,0-.25.1l-.74.72-.74-.72a.31.31,0,0,0-.24-.1H4.73a.31.31,0,0,0-.18,0l-1.31.8L2.69,4.8a.35.35,0,0,0-.27-.13H2.35A1.56,1.56,0,0,1,.8,3.11V2.35A1.56,1.56,0,0,1,2.35.8H13.77A1.56,1.56,0,0,1,15.33,2.35Z" />
                    </svg>
                </MenuSectionItem> */}
                <MenuSectionItem title='REPORTS' _onClick={() => navigate('/reports')} _isActive={location.pathname === '/reports'}>
                    <section
                        onClick={() => navigate('/reports')}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            id="Layer_1"
                            x={0}
                            y={0}
                            viewBox="0 0 23 20"
                            style={{
                                enableBackground: "new 0 0 83.31 104.02",
                            }}
                            xmlSpace="preserve"
                            width={23}
                            height={28.718}
                        >
                            <style>{".st0{fill:currentColor}"}</style>
                            <path
                                className="st0"
                                d="M6.126 25.868h-5.428a0.692 0.692 0 0 1 -0.698 -0.69V7.688c0 -0.382 0.313 -0.69 0.698 -0.69h5.428c0.386 0 0.698 0.308 0.698 0.69v17.489a0.692 0.692 0 0 1 -0.698 0.69zm-4.725 -1.38H5.428V8.378H1.4v16.109z"
                            />
                            <path
                                className="st0"
                                d="M11.444 25.868h-5.318a0.692 0.692 0 0 1 -0.698 -0.69v-7.297c0 -0.382 0.313 -0.69 0.698 -0.69h5.318c0.386 0 0.698 0.308 0.698 0.69v7.297c0 0.382 -0.313 0.69 -0.698 0.69zm-4.617 -1.38h3.917v-5.917H6.826v5.917zm15.471 1.38h-5.425a0.692 0.692 0 0 1 -0.698 -0.69V5.357c0 -0.382 0.313 -0.69 0.698 -0.69h5.428c0.386 0 0.698 0.308 0.698 0.69v19.821a0.692 0.692 0 0 1 -0.698 0.69zm-4.73 -1.38h4.027V6.046h-4.027v18.441z"
                            />
                            <path
                                className="st0"
                                d="M16.872 25.868h-5.428a0.692 0.692 0 0 1 -0.698 -0.69V0.69c0 -0.382 0.313 -0.69 0.698 -0.69h5.428c0.386 0 0.698 0.308 0.698 0.69v24.488c0 0.382 -0.313 0.69 -0.698 0.69zm-4.73 -1.38h4.027V1.38h-4.027v23.108zm10.167 4.23H0.69a0.69 0.69 0 0 1 0 -1.38h21.62a0.69 0.69 0 0 1 0 1.38z"
                            />
                        </svg>
                    </section>

                </MenuSectionItem>
                <MenuSectionItem title='SMARTLINK'>
                    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 16.13 16.13" width="25px" height="25px">
                        <path fill="currentColor" d="M8.06,16.13a8.07,8.07,0,1,1,8.07-8.07A8.08,8.08,0,0,1,8.06,16.13ZM8.06.75a7.32,7.32,0,1,0,7.32,7.31A7.32,7.32,0,0,0,8.06.75Z" />
                        <path fill="currentColor" d="M5.92,12.35A2,2,0,0,1,4.53,9L5.74,7.77a.41.41,0,0,1,.56,0,.4.4,0,0,1,.12.29.39.39,0,0,1-.12.28L5.09,9.55a1.17,1.17,0,1,0,1.66,1.66L8.32,9.64a1.14,1.14,0,0,0,.35-.83A1.13,1.13,0,0,0,8.32,8a.64.64,0,0,0-.15-.13A.39.39,0,0,1,8,7.59a.46.46,0,0,1,.07-.3.42.42,0,0,1,.33-.16.39.39,0,0,1,.23.07,3,3,0,0,1,.26.21,2,2,0,0,1,.57,1.4,1.92,1.92,0,0,1-.57,1.39L7.31,11.78a1.94,1.94,0,0,1-1.39.57Z" />
                        <path fill="currentColor" d="M7.73,9.35a.48.48,0,0,1-.23-.07,3,3,0,0,1-.26-.22l0,0a.69.69,0,0,1-.11-.13l0,0v0A.76.76,0,0,1,7,8.75v0a.6.6,0,0,1-.09-.15,2,2,0,0,1-.23-.91,2,2,0,0,1,.58-1.4L8.81,4.7a2,2,0,0,1,1.4-.58,2,2,0,0,1,1.39.58,2,2,0,0,1,0,2.79L10.39,8.7a.39.39,0,0,1-.28.12.4.4,0,0,1-.28-.68L11,6.92a1.16,1.16,0,0,0,.34-.83,1.17,1.17,0,0,0-2-.83L7.8,6.84A1.18,1.18,0,0,0,7.54,8.1l0,.1h0l0,.07,0,0,0,0,0,0,0,0,.06.07h0L8,8.63a.4.4,0,0,1,.1.55A.4.4,0,0,1,7.73,9.35Z" />
                    </svg>
                </MenuSectionItem>
            </section>

        </section>
    )
}
const MenuSectionItem = ({ children, title, _onClick, _isActive }) => {
    const [isHovered, setIsHovered] = useState(false);
    const selectedColor = useSelector(state => state.settings.selectedColor);
    const colors = useSelector(state => state.settings.colors);

    return (
        <button
            onClick={_onClick && _onClick}
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                // gap: '5px',
                height: '100%',
                width: '120px',

                backgroundColor: _isActive ? colors[selectedColor].fixed : 'transparent',
                border: 'none',
                color: isHovered ? _isActive ? 'white' : colors[selectedColor].fixed : colors[selectedColor].headerBgColor ? 'black' : 'white'
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}>
            {children}
            <p
                style={{
                    fontSize: '12px',
                    fontWeight: '500',
                    ..._isActive && ({ color: 'black' })
                }}>{title}</p>
        </button>
    )
}
const UserInfoSection = () => {
    const getCurrentTimeUTC = () => {
        return new Date().toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', timeZone: 'UTC' });
    };

    const getCurrentTime = () => {
        return new Date().toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', hour12: false, timeZone: 'UTC' });
    };

    const [time, setTime] = useState(getCurrentTime());
    const selectedColor = useSelector(state => state.settings.selectedColor);
    const colors = useSelector(state => state.settings.colors);

    useEffect(() => {
        const timer = setInterval(() => {
            setTime(getCurrentTime());
        }, 60000);

        return () => clearInterval(timer);
    }, []);

    return (
        <section style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            height: '100%',
        }}>
            <VerticalDots />
            <section style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '15px',

                backgroundColor: colors[selectedColor].gradienStart,
                height: '100%',
                minWidth: '210px',
            }}>
                <section style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '35px',
                    width: '35px',
                    border: '2px solid black',
                    borderRadius: '100%',
                }}>
                    <p style={{ fontSize: '15px', fontWeight: '700' }}>TT</p>
                    {/* MA */}
                </section>
                <section style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '5px',

                    borderLeft: '2px solid black',
                    height: '65%',
                    paddingLeft: '15px',
                }}>
                    <p style={{ fontSize: '25px', fontWeight: '500' }}>{time}</p>
                    <p style={{ fontSize: '15px' }}>UTC</p>
                </section>
            </section>

        </section>
    )
}
const VerticalDots = () => {
    const [toggle, setToggle] = useState(false);
    const [toggleMenu, setToggleMenu] = useState(false);

    const toggleMenuAnimation = useSpring({
        opacity: toggleMenu ? 0 : 1,
    })

    const buttonAnimation = useSpring({
        gap: !toggle ? '3.5px' : '0px',
    })
    useEffect(() => {
        setToggleMenu(!toggle);
    }, [toggle]);

    const topDotAnimation = useSpring({
        transform: toggle ? 'translateY(2px) rotate(45deg)' : 'translateY(0px) rotate(0deg)',
        width: toggle ? '15px' : '3.5px',
    });

    const bottomDotAnimation = useSpring({
        transform: toggle ? 'translateY(-2px) rotate(-45deg)' : 'translateY(0px) rotate(0deg)',
        width: toggle ? '15px' : '3.5px',
    });

    const centerDotAnimation = useSpring({
        opacity: toggle ? 0 : 1,
        display: toggle ? 'none' : 'block',
        width: '3.5px',
    });

    const dotStyle = {
        height: '3.5px',
        backgroundColor: 'white',
    };

    return (
        <section>
            <animated.button onClick={() => setToggle(!toggle)} style={{
                ...buttonAnimation,
                position: 'relative',

                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'transparent',
                border: 'none',
                width: '30px',
                height: '100%',
            }}>
                <animated.div style={{ ...dotStyle, ...topDotAnimation, borderRadius: '100px' }}></animated.div>
                <animated.div style={{ ...dotStyle, ...centerDotAnimation, borderRadius: '100px' }}></animated.div>
                <animated.div style={{ ...dotStyle, ...bottomDotAnimation, borderRadius: '100px' }}></animated.div>
                <animated.section style={{
                    ...toggleMenuAnimation,
                    position: 'absolute',

                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    paddingLeft: '10px',

                    left: '-150px',
                    top: '-14px',
                    height: '40px',
                    width: '150px',
                    backgroundColor: '#272a32',

                    boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.2)'
                }}>
                    <p style={{ color: 'white', fontSize: '13px', fontWeight: '500' }}>Edit menu</p>
                </animated.section>
            </animated.button>


        </section>
    );
}
const MenuLines = () => {
    const [toggle, setToggle] = useState(false);
    const [toggleMenu, setToggleMenu] = useState(false);
    const selectedColor = useSelector(state => state.settings.selectedColor);
    const colors = useSelector(state => state.settings.colors);


    const toggleMenuAnimation = useSpring({
        opacity: toggleMenu ? 0 : 1,
        transform: toggle ? 'translateY(0px)' : 'translateY(-20px)',
        pointerEvents: toggleMenu ? 'none' : 'auto',
    })
    useEffect(() => {
        setToggleMenu(!toggle);
    }, [toggle]);

    const buttonAnimation = useSpring({
        gap: !toggle ? '8px' : '0px',
    })

    const topLineAnimation = useSpring({
        transform: toggle ? 'translateY(1px) rotate(45deg)' : 'translateY(0px) rotate(0deg)',
    });

    const bottomLineAnimation = useSpring({
        transform: toggle ? 'translateY(-1px) rotate(-45deg)' : 'translateY(0px) rotate(0deg)',
    });

    const centerLineAnimation = useSpring({
        opacity: toggle ? 0 : 1,
        display: toggle ? 'none' : 'block',
    });

    const lineStyle = {
        height: '2px',
        width: '100%',
        backgroundColor: colors[selectedColor].headerBgColor ? 'black' : 'white',
    };

    return (
        <animated.button onClick={() => setToggle(!toggle)} style={{
            ...buttonAnimation,
            position: 'relative',

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'transparent',
            marginRight: '20px',
            border: 'none',
            width: '30px',
            height: '100%',
        }}>
            <animated.div style={{ ...lineStyle, ...topLineAnimation, borderRadius: '10px' }}></animated.div>
            <animated.div style={{ ...lineStyle, ...centerLineAnimation, borderRadius: '10px' }}></animated.div>
            <animated.div style={{ ...lineStyle, ...bottomLineAnimation, borderRadius: '10px' }}></animated.div>
            <animated.section style={{
                ...toggleMenuAnimation,
                position: 'absolute',

                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
                paddingLeft: '10px',

                left: '-20px',
                top: '73px',
                height: 'calc(100vh - 80px)',
                width: '350px',
                backgroundColor: '#272a32',

                boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.2)'
            }}>
                <p style={{ color: 'white', fontSize: '13px', fontWeight: '500' }}></p>
            </animated.section>
        </animated.button>
    )
}

