const createRandomSettingItem = (id) => {
    const countries = ['Afghanistan', 'Albania', 'Algeria', 'American Samoa', 'Andorra', 'Angola'];
    const country = countries[Math.floor(Math.random() * countries.length)];
    const hasCustomDate = Math.random() > 0.5;

    const customDate = hasCustomDate
        ? [new Date(2024, Math.floor(Math.random() * 12), Math.floor(Math.random() * 28 + 1)).toISOString().slice(0, 10),
        new Date(2024, Math.floor(Math.random() * 12), Math.floor(Math.random() * 28 + 1)).toISOString().slice(0, 10)]
        : [];

    return {
        id: id || country,
        country,
        leads: Math.floor(Math.random() * 500),
        payouts: Math.floor(Math.random() * 100),
        profitPerPayout: Math.floor(Math.random() * 100 + 100),
        hasCustomDate,
        customDate
    };
}

export const generateSettingItems = (count) => {
    const settingItems = [];
    for (let i = 0; i < count; i++) {
        settingItems.push(createRandomSettingItem(i));
    }
    return settingItems;
}

// Генеруємо 5 нових settingItems для тестування
//   const newSettingItems = generateSettingItems(5);
//   console.log(newSettingItems);
