import React from 'react'
import DropDown from './DropDown'

export default function TopCampaign() {
    return (
        <section style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '15px',

            width: '100%',
            minHeight: '500px',
            backgroundColor: '#272a32',
            borderRadius: '10px',
        }}>
            <section style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                padding: '15px',

                height: '70px',
                width: '100%',
            }}>
                <p style={{ color: 'white', fontSize: '19px', fontWeight: '400', verticalAlign: 'bottom' }}>Top Campaigns</p>
                <section style={{
                    paddingTop:'5px',
                }}>
                    <DropDown defaultTitle='Campaign' fontColor='white'/>
                </section>

            </section>
            <section style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                gap: '10px',
                paddingRight: '25px',
                width: '100%',
            }}>
                <EditButton>
                    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" viewBox="0 0 24 24" style={{ fontSize: '1.5rem', color: 'white' }}>
                        <path fill="currentColor" d="M6 4v5v-5v16v-.238V20V4Zm0 18q-.825 0-1.413-.588T4 20V4q0-.825.588-1.413T6 2h7.175q.4 0 .763.15t.637.425l4.85 4.85q.275.275.425.638t.15.762v3.525q-.475-.175-.975-.263T18 12V9h-3q-.825 0-1.413-.588T13 7V4H6v16h6.35q.2.575.5 1.075t.7.925H6Zm12-2.575L16.6 20.8q-.275.275-.688.288T15.2 20.8q-.275-.275-.275-.7t.275-.7l1.4-1.4l-1.4-1.4q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275l1.4 1.4l1.4-1.4q.275-.275.688-.287t.712.287q.275.275.275.7t-.275.7L19.425 18l1.375 1.4q.275.275.288.688t-.288.712q-.275.275-.7.275t-.7-.275L18 19.425Z"></path>
                    </svg>
                </EditButton>

                <EditButton>
                    <svg xmlns="http://www.w3.org/2000/svg" role="img" width="2em" height="2em"  viewBox="0 0 33 33" style={{ fontSize: '10rem', color: 'white' }}>
                        <path fill="currentColor" xmlns="http://www.w3.org/2000/svg" className="cls-1" d="M22.51,12.5l-1.12-1.12a1.07,1.07,0,0,0-.74-.3H12.32a1.23,1.23,0,0,0-1.24,1.24v9.26a1.24,1.24,0,0,0,1.24,1.24h9.26a1.25,1.25,0,0,0,1.24-1.24V13.25A1.06,1.06,0,0,0,22.51,12.5Zm-2,3.94H13.42a.87.87,0,0,0-.88.87V21.8h-.22a.22.22,0,0,1-.22-.22V12.32a.22.22,0,0,1,.22-.22H13v2.53a.87.87,0,0,0,.87.88H20a.87.87,0,0,0,.88-.88V12.31l.93.94v8.33a.22.22,0,0,1-.22.22h-.22V17.31A.88.88,0,0,0,20.48,16.44Zm-.15,1V21.8H13.56V17.46ZM19.17,14V12.56a.51.51,0,0,0-.29-.46h1v2.39h-1A.51.51,0,0,0,19.17,14Zm-1-1.47V14a.51.51,0,0,0,.29.46H14.05V12.1h4.38A.51.51,0,0,0,18.14,12.56Z"/>
                    </svg>
                </EditButton>

                <EditButton>
                    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="2em" height="2em" viewBox="0 0 33 33" style={{ fontSize: '10rem', color: 'white' }}>
                        <path d="M11.84,14.79V14.5a.12.12,0,0,1,.12-.13h1.39V12.15H11.88a1.22,1.22,0,0,0-1.22,1.21v9a1.22,1.22,0,0,0,1.22,1.22h1.47V21.38H12a.12.12,0,0,1-.12-.12V21a.12.12,0,0,1,.12-.12h1.39v-.76H12a.12.12,0,0,1-.12-.12v-.29a.12.12,0,0,1,.12-.13h1.39V18.8H12a.12.12,0,0,1-.12-.13v-.29a.12.12,0,0,1,.12-.12h1.39V17.5H12a.12.12,0,0,1-.12-.12v-.29A.12.12,0,0,1,12,17h1.39v-.75H12a.12.12,0,0,1-.12-.13v-.29a.12.12,0,0,1,.12-.12h1.39v-.76H12A.12.12,0,0,1,11.84,14.79Z"/>
                        <path d="M20.1,12.15v2.22h1.13a.13.13,0,0,1,.13.13v.29a.12.12,0,0,1-.13.12H20.1v.76h1.13a.12.12,0,0,1,.13.12v.29a.13.13,0,0,1-.13.13H20.1V17h1.13a.13.13,0,0,1,.13.13v.29a.12.12,0,0,1-.13.12H20.1v.76h1.13a.12.12,0,0,1,.13.12v.29a.13.13,0,0,1-.13.13H20.1v.75h1.13a.13.13,0,0,1,.13.13V20a.13.13,0,0,1-.13.12H20.1v.76h1.13a.12.12,0,0,1,.13.12v.29a.12.12,0,0,1-.13.12H20.1v2.08s0,.05,0,.08h1.23a1.22,1.22,0,0,0,1.21-1.22V16"/><rect className="cls-1" x="15.37" y="13.49" width="2.69" height="0.67" rx="0.16"/><rect className="cls-1" x="15.37" y="15.12" width="2.69" height="0.67" rx="0.16"/><rect className="cls-1" x="15.37" y="16.74" width="2.69" height="0.67" rx="0.16"/><rect className="cls-1" x="15.37" y="18.36" width="2.69" height="0.67" rx="0.16"/><rect className="cls-1" x="15.37" y="19.99" width="2.69" height="0.67" rx="0.16"/><rect className="cls-1" x="15.37" y="21.61" width="2.69" height="0.67" rx="0.16"/>
                        <path fill="currentColor" d="M22.94,16.07a1.41,1.41,0,0,1-.41-.07v6.32a1.22,1.22,0,0,1-1.21,1.22H20.09s0-.05,0-.08V21.38h1.13a.12.12,0,0,0,.13-.12V21a.12.12,0,0,0-.13-.12H20.1v-.76h1.13a.13.13,0,0,0,.13-.12v-.29a.13.13,0,0,0-.13-.13H20.1V18.8h1.13a.13.13,0,0,0,.13-.13v-.29a.12.12,0,0,0-.13-.12H20.1V17.5h1.13a.12.12,0,0,0,.13-.12v-.29a.13.13,0,0,0-.13-.13H20.1v-.75h1.13a.13.13,0,0,0,.13-.13v-.29a.12.12,0,0,0-.13-.12H20.1v-.76h1.13a.12.12,0,0,0,.13-.12V14.5a.13.13,0,0,0-.13-.13H20.1V12.15h-1V23.46A1.09,1.09,0,0,1,18,24.55H15.43a1.08,1.08,0,0,1-1.08-1.09V12.22a1.08,1.08,0,0,1,1.08-1.08h2.35a1.27,1.27,0,0,1-.06-.39,1.25,1.25,0,0,1,.15-.61H15.43a2.06,2.06,0,0,0-1.83,1.11H11.88a2.11,2.11,0,0,0-2.12,2.11v9a2.12,2.12,0,0,0,2.12,2.12H13.6a2.06,2.06,0,0,0,1.83,1.11H18a2.08,2.08,0,0,0,1.83-1.11h1.47a2.12,2.12,0,0,0,2.11-2.12V16A1.34,1.34,0,0,1,22.94,16.07ZM11.88,23.54a1.22,1.22,0,0,1-1.22-1.22v-9a1.22,1.22,0,0,1,1.22-1.21h1.47v2.22H12a.12.12,0,0,0-.12.13v.29a.12.12,0,0,0,.12.12h1.39v.76H12a.12.12,0,0,0-.12.12v.29a.12.12,0,0,0,.12.13h1.39V17H12a.12.12,0,0,0-.12.13v.29a.12.12,0,0,0,.12.12h1.39v.76H12a.12.12,0,0,0-.12.12v.29a.12.12,0,0,0,.12.13h1.39v.75H12a.12.12,0,0,0-.12.13V20a.12.12,0,0,0,.12.12h1.39v.76H12a.12.12,0,0,0-.12.12v.29a.12.12,0,0,0,.12.12h1.39v2.16Z"/>
                        <path fill="currentColor" d="M26.11,10.22H23.47V7.59a.54.54,0,1,0-1.07,0v2.63H19.77a.54.54,0,0,0,0,1.07H22.4v2.63a.54.54,0,1,0,1.07,0V11.29h2.64a.54.54,0,0,0,0-1.07Z"/>
                    </svg>
                </EditButton>

            </section>

        </section>
    )
}

const EditButton = ({ children }) => {
    return (
        <button style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',

            borderRadius: '200px',
            border: '1px solid white',
            backgroundColor: 'transparent',
            height:'40px',
            width:'40px',
            overflow:'hidden'
        }}>
            {children}
        </button>
    )
}
