import React, { useEffect, forwardRef, useState } from 'react'
import { useSpring, animated, config } from 'react-spring';
import { Dispatch } from 'react';
import DatePicker from 'react-datepicker';
import { allCountries } from '../servises/data';

import { user, isUserAuthenticated, changeToken, changePassword, getUsers, removeUser, addUser, logout } from '../servises/admin';
//services
import { saveGraphData, loadGraphData, saveSettingsItems,loadSettingsItems } from '../servises/graphData';
//Store
import { useSelector, useDispatch } from 'react-redux';
import { setNewColorAction, setGlobalGraphData } from '../store/settingsReducer';

export default function SettingsSection({ settingsToggle, setSettingsToggle }) {
  const [isHovered, setIsHovered] = useState(false);
  const logoutFunc = () => {
    const isConfirmed = window.confirm('Ви дійсно хочете вийти?');
    if (isConfirmed) {
      logout();
    }
  };

  return (
    <section style={{
      position: 'fixed',
      bottom: '0px',
      left: '0px',

      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      width: '100%',
      height: '50px'
    }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      {
        isHovered &&
        <section style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '20px'
        }}>
          <SettingsButton callback={() => { setSettingsToggle(!settingsToggle) }} />
          {isUserAuthenticated() && <LogoutButton callback={logoutFunc} />}
        </section>
      }

    </section>
  )
}
const SettingsButton = ({ callback }) => {
  return (
    <button
      onClick={callback}
      style={{
        height: '50px',
        width: '50px',

        borderRadius: '20px',
        border: 'none'
      }}>
      <span className="material-symbols-outlined">arrow_upward</span>
    </button>
  )
}
const LogoutButton = ({ callback }) => {
  return (
    <button
      onClick={callback}
      style={{
        height: '50px',
        width: '50px',

        borderRadius: '20px',
        border: 'none'
      }}>
      <span className="material-symbols-outlined">logout</span>
    </button>
  )
}
const SelectColorButton = ({ onClick, color, isSelected }) => {
  return (
    <button
      onClick={onClick}
      style={{
        height: '50%',
        aspectRatio: '1/1',
        borderRadius: '50%',
        border: isSelected ? '2px solid black' : 'none',
        backgroundColor: color
      }}>
    </button>
  );
};
const ColorChanger = () => {
  const dispatch = useDispatch();
  const selectedColor = useSelector(state => state.settings.selectedColor);
  const colors = useSelector(state => state.settings.colors);

  return (
    <section style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '10px',
      width: '100%',
      height: '60px',
    }}>
      {Object.keys(colors).map(colorKey => (
        <SelectColorButton
          key={colorKey}
          onClick={() => dispatch(setNewColorAction(colorKey))}
          color={colors[colorKey].fixed}
          isSelected={selectedColor === colorKey}
        />
      ))}
    </section>
  );
};

export const SettingsWindow = ({ toggle, setSettingsWindowToggle, setGraphDataFromSettings, changeFilterDataRange, countriesSelected }) => {
  const [settingItems, setSettingItems] = useState([])

  const [allLeads, setAllLeads] = useState(0)
  const [allPayouts, setAllPayouts] = useState(0)
  const [isSyncCounties, setIsSyncCountries] = useState(false);
  const [addCountryTitle, setAddCountryTitle] = useState('');
  const [currentWindow, setCurrentWindow] = useState('settings')
  const [graphData, setGraphData] = useState([])
  const [generatingStatusText, setGeneratingStatusText] = useState('')
  const [enable, setEnable] = useState(false)

  const dispatch = useDispatch()

  const switchSyncCountries = (state) => {
    // Display a confirmation dialog
    let userConfirmed;
    if (state === true) {
      userConfirmed = window.confirm("Якщо ви включите цей параметер - поточні країни видаляться та встановляться країни, що вибрані в трекері.");
    } else {
      userConfirmed = window.confirm("Якщо ви виключите цей параметер - країни видаляться, та їх можна буде додати вручну.");
    }

    if (userConfirmed) {
      setIsSyncCountries(state);
      if (state) {
        checkCountriesFromTracker()
      } else {
        setSettingItems([])
        setAllLeads(0);
        setAllPayouts(0);
      }
    } else {
      return;
    }
  }

  const addNewCountry = (country) => {
    
    const newItem = {
      subId: Date.now(),
      id: country,
      country,
      leads: 0,
      payouts: 0,
      profitPerPayout: 0,
      hasCustomDate: false,
      customDate: []
    };

    const newSettingItems = [...settingItems, newItem];

    setSettingItems(newSettingItems);
    setAddCountryTitle('')
    const totalLeads = newSettingItems.reduce((acc, item) => acc + item.leads, 0);
    const totalPayouts = newSettingItems.reduce((acc, item) => acc + item.payouts, 0);

    setAllLeads(totalLeads);
    setAllPayouts(totalPayouts);
  }

  const addCountryCustomDate = (subId, date) => {
    const newSettingItems = settingItems.map((c) => {
      if (c.subId === subId) {
        if (c.hasCustomDate === false) return c
        return { ...c, customDate: date }
      }
      else return c
    })
    console.log(newSettingItems);
    setSettingItems(newSettingItems)
  }

  const toggleCountryCustomDate = (subId) => {
    const newSettingItems = settingItems.map((c) => {
      if (c.subId === subId) {
        const newValue = !c.hasCustomDate
        if (newValue === false) {
          return { ...c, hasCustomDate: newValue, customDate: [] }
        } else {
          return { ...c, hasCustomDate: !c.hasCustomDate }
        }
      }
      else return c
    })
    setSettingItems(newSettingItems)
  }

  const syncCountries = (countriesSelected, items) => {
    const updatedItems = countriesSelected.map(country => {
      const existingItem = items.find(item => item.id === country);
      return existingItem || { id: country, country, leads: 0, payouts: 0, profitPerPayout: 0, hasCustomDate: false, customDate: [] };
    });
    return updatedItems;
  }

  const checkCountriesFromTracker = () => {
    const newItems = syncCountries(countriesSelected, settingItems);
    setSettingItems(newItems);
    const totalLeads = newItems.reduce((acc, item) => acc + item.leads, 0);
    const totalPayouts = newItems.reduce((acc, item) => acc + item.payouts, 0);

    setAllLeads(totalLeads);
    setAllPayouts(totalPayouts);
  }

  const editSettingItem = (subId, property, value) => {
    const numericValue = Number(value);
    if (isNaN(numericValue)) {
      return;
    }
    const updatedItems = settingItems.map(item => {
      if (item.subId === subId) {
        return { ...item, [property]: numericValue };
      }
      return item;
    });

    setSettingItems(updatedItems);
    const totalLeads = updatedItems.reduce((acc, item) => acc + item.leads, 0);
    const totalPayouts = updatedItems.reduce((acc, item) => acc + item.payouts, 0);

    setAllLeads(totalLeads);
    setAllPayouts(totalPayouts);
  };

  const removeSettingItem = (subId) => {
    const filteredSettingsItems = settingItems.filter(item => item.subId !== subId)
    setSettingItems(filteredSettingsItems);
    const totalLeads = filteredSettingsItems.reduce((acc, item) => acc + item.leads, 0);
    const totalPayouts = filteredSettingsItems.reduce((acc, item) => acc + item.payouts, 0);

    setAllLeads(totalLeads);
    setAllPayouts(totalPayouts);
  };

  const [selectedDate, setSelectedDate] = useState([null, null]);
  const changeSelectedDate = (dates) => {
    setSelectedDate(dates);
  };

  const formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const year = d.getFullYear().toString().substr(-2);
    return `${day}/${month}/${year}`;
  };

  const generateNewGraphData = () => {
    setGeneratingStatusText('Обробка...');
    if (!selectedDate[0] || !selectedDate[1]) {
      alert('Виберіть дату');
      setGeneratingStatusText('Виберіть дату та спробуйте знову');
      return;
    }
    const defaultItems = settingItems.filter(item => !item.hasCustomDate);
    const customDateItems = settingItems.filter(item => item.hasCustomDate);

    // Генеруємо графік для об'єктів без кастомних дат
    let result = generateGraph(defaultItems, selectedDate);
    let graphData = result.graphData;
    let overallStartDate = result.startDate;
    let overallEndDate = result.endDate;

    // Генеруємо окремі графіки для кожного об'єкта з кастомною датою
    customDateItems.forEach(item => {
      let customResult = generateGraph([item], item.customDate);
      graphData = mergeGraphData(graphData, customResult.graphData);
      overallStartDate = customResult.startDate < overallStartDate ? customResult.startDate : overallStartDate;
      overallEndDate = customResult.endDate > overallEndDate ? customResult.endDate : overallEndDate;
    });

    // console.log(graphData);
    setGraphData(graphData);
    saveGraphData(graphData);
    saveSettingsItems(settingItems)
    dispatch(setGlobalGraphData(graphData))

    setGraphDataFromSettings(graphData);
    changeFilterDataRange('period', [overallStartDate, overallEndDate]);
    // Оновлення статусу генерації графіку
    setGeneratingStatusText('Графік оновлено');
  };

  const generateGraph = (items, dateRange) => {
    const startDate = new Date(dateRange[0]);
    const endDate = new Date(dateRange[1]);
    const oneDay = 24 * 60 * 60 * 1000;
    const totalDays = Math.round(Math.abs((endDate - startDate) / oneDay)) + 1;

    let graphData = {};

    for (let day = 0; day < totalDays; day++) {
      const currentDate = new Date(startDate);
      currentDate.setDate(currentDate.getDate() + day);
      const dateKey = formatDate(currentDate);

      graphData[dateKey] = {};

      items.forEach(item => {
        graphData[dateKey][item.country] = {
          leads: 0,
          clicks: 0,
          impressions: 0,
          "FTD's": 0,
          price: item.profitPerPayout,
          byHours: {}
        };
      });
    }

    items.forEach(item => {
      let remainingLeads = item.leads;
      let remainingPayouts = item.payouts;

      // Розподіл лідів та "FTD's" (виплат) випадково за днями
      for (let i = 0; i < remainingLeads; i++) {
        const randomDayIndex = Math.floor(Math.random() * totalDays);
        const randomDate = new Date(startDate);
        randomDate.setDate(randomDate.getDate() + randomDayIndex);
        const dateKey = formatDate(randomDate);

        graphData[dateKey][item.country].leads++;
      }

      for (let i = 0; i < remainingPayouts; i++) {
        const randomDayIndex = Math.floor(Math.random() * totalDays);
        const randomDate = new Date(startDate);
        randomDate.setDate(randomDate.getDate() + randomDayIndex);
        const dateKey = formatDate(randomDate);

        graphData[dateKey][item.country]["FTD's"]++;
      }

      // Обрахунок кліків та вражень (impressions)
      for (const date in graphData) {
        const leadsForDate = graphData[date][item.country].leads;
        const clickMultiplier = 1.3 + Math.random() * 0.1;
        const clicksForDate = Math.floor(leadsForDate * clickMultiplier);
        graphData[date][item.country].clicks += clicksForDate;

        const dailyImpressions = graphData[date][item.country].clicks + Math.floor(Math.random() * 6);
        graphData[date][item.country].impressions += dailyImpressions;

        // Розподіл годинних метрик
        const currentDate = new Date(parseDate(date));
        const metrics = {
          leads: graphData[date][item.country].leads,
          impressions: dailyImpressions,
          clicks: graphData[date][item.country].clicks,
          "FTD's": graphData[date][item.country]["FTD's"]
        };
        const hourlyMetrics = distributeMetricsOverHours(metrics, currentDate, totalDays);
        graphData[date][item.country].byHours = hourlyMetrics;
      }
    });

    return {
      graphData,
      startDate,
      endDate
    };
  };

  const deepMergeObjects = (obj1, obj2) => {
    let merged = { ...obj1 };
    for (let key in obj2) {
      if (merged[key] && typeof merged[key] === 'object' && typeof obj2[key] === 'object') {
        merged[key] = deepMergeObjects(merged[key], obj2[key]);
      } else {
        merged[key] = obj2[key];
      }
    }
    return merged;
  }

  const mergeGraphData = (graphData1, graphData2) => {
    let mergedGraphData = { ...graphData1 };

    // Визначаємо всі унікальні країни в обох наборах даних
    let allCountries = new Set();
    [graphData1, graphData2].forEach(dataSet => {
      Object.values(dataSet).forEach(date => {
        Object.keys(date).forEach(country => {
          allCountries.add(country);
        });
      });
    });

    // Додавання країн, яких немає в graphData1, з нульовими значеннями
    Object.keys(mergedGraphData).forEach(date => {
      allCountries.forEach(country => {
        if (!mergedGraphData[date][country]) {
          mergedGraphData[date][country] = { leads: 0, clicks: 0, impressions: 0, "FTD's": 0, price: 0, byHours: {} };
        }
      });
    });

    // Об'єднання даних з graphData2
    for (const date in graphData2) {
      if (!mergedGraphData[date]) {
        mergedGraphData[date] = {};
      }

      allCountries.forEach(country => {
        if (!mergedGraphData[date][country]) {
          mergedGraphData[date][country] = { leads: 0, clicks: 0, impressions: 0, "FTD's": 0, price: 0, byHours: {} };
        }

        if (graphData2[date][country]) {
          for (const metric in graphData2[date][country]) {
            if (metric !== 'byHours') {
              mergedGraphData[date][country][metric] += graphData2[date][country][metric];
            } else {
              // Глибоке об'єднання об'єктів byHours
              mergedGraphData[date][country].byHours = deepMergeObjects(
                mergedGraphData[date][country].byHours,
                graphData2[date][country].byHours
              );
            }
          }
        }
      });
    }

    return mergedGraphData;
  };

  function parseDate(dateString) {
    const parts = dateString.split('/');
    if (parts.length === 3) {
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1; // Місяці починаються з 0 в JavaScript
      let year = parseInt(parts[2], 10);
      year += (year < 100) ? 2000 : 0; // Додати 2000 лише якщо рік подано двома цифрами
      return new Date(Date.UTC(year, month, day));
    } else {
      console.log('Неправильний формат дати');
      throw new Error('Неправильний формат дати');
    }
  }

  function distributeEvenly(value, hoursToDistribute) {
    let distribution = new Array(hoursToDistribute).fill(0);
    let distributed = 0;

    // Розподіляємо значення випадковим чином
    while (distributed < value) {
      let randomIndex = Math.floor(Math.random() * hoursToDistribute);
      distribution[randomIndex]++;
      distributed++;
    }

    // Перевіряємо, чи загальна сума дорівнює value
    let sum = distribution.reduce((a, b) => a + b, 0);
    let difference = value - sum;

    // Якщо є різниця, розподіляємо її
    while (difference > 0) {
      let randomIndex = Math.floor(Math.random() * hoursToDistribute);
      distribution[randomIndex]++;
      difference--;
    }

    return distribution;
  }

  function redistribute(savedValues, array, constraintArray) {
    while (savedValues > 0) {
      let randomIndex = Math.floor(Math.random() * array.length);
      if (!constraintArray || array[randomIndex] < constraintArray[randomIndex]) {
        array[randomIndex]++;
        savedValues--;
      }
    }
  }

  function distributeMetrics(metrics, hoursToDistribute) {
    let impressions = distributeEvenly(metrics.impressions, hoursToDistribute);
    let clicks = distributeEvenly(metrics.clicks, hoursToDistribute);
    let leads = distributeEvenly(metrics.leads, hoursToDistribute);
    let ftds = distributeEvenly(metrics["FTD's"], hoursToDistribute);

    for (let hour = 0; hour < hoursToDistribute; hour++) {
      let savedClicks = clicks[hour] - Math.min(clicks[hour], impressions[hour]);
      clicks[hour] = Math.min(clicks[hour], impressions[hour]);

      let savedLeads = leads[hour] - Math.min(leads[hour], clicks[hour]);
      leads[hour] = Math.min(leads[hour], clicks[hour]);

      let savedFtds = ftds[hour] - Math.min(ftds[hour], leads[hour], clicks[hour]);
      ftds[hour] = Math.min(ftds[hour], leads[hour], clicks[hour]);

      // Перерозподіляємо "зекономлені" значення
      redistribute(savedClicks, clicks, impressions);
      redistribute(savedLeads, leads, clicks);
      redistribute(savedFtds, ftds, Math.min.apply(Math, [leads, clicks]));
    }

    return {
      impressions,
      clicks,
      leads,
      "FTD's": ftds
    };
  }

  function distributeMetricsOverHours(metrics, currentDate) {
    const now = new Date(); // Використовуємо локальний час для визначення UTC
    const currentUTCHour = now.getUTCHours();
    const currentUTCMinutes = now.getUTCMinutes();

    // Використовуємо функцію parseDate для отримання дати з рядка, якщо потрібно
    const inputDate = typeof currentDate === 'string' ? parseDate(currentDate) : currentDate;

    // Визначаємо, чи є inputDate сьогоднішньою датою в UTC
    const isCurrentDay = inputDate.getUTCFullYear() === now.getUTCFullYear() &&
      inputDate.getUTCMonth() === now.getUTCMonth() &&
      inputDate.getUTCDate() === now.getUTCDate();

    // Визначаємо кількість годин для розподілу: до поточної години, якщо сьогодні, інакше — 24
    const hoursToDistribute = isCurrentDay ? currentUTCHour + (currentUTCMinutes > 0 ? 1 : 0) : 24;

    let distribution = distributeMetrics(metrics, hoursToDistribute);

    // Обнуляємо метрики для непотрібних годин, якщо сьогодні
    if (isCurrentDay) {
      for (let hour = hoursToDistribute; hour < 24; hour++) {
        distribution.impressions[hour] = 0;
        distribution.clicks[hour] = 0;
        distribution.leads[hour] = 0;
        distribution["FTD's"][hour] = 0;
      }
    }

    // Створюємо об'єкт для годинної розподілу
    const hourlyDistribution = {};
    for (let hour = 0; hour < 24; hour++) {
      hourlyDistribution[hour] = {
        impressions: distribution.impressions[hour],
        clicks: distribution.clicks[hour],
        leads: distribution.leads[hour],
        "FTD's": distribution["FTD's"][hour],
      };
    }

    return hourlyDistribution;
  }

  const backgroundAnimation = useSpring({
    backgroundColor: toggle ? 'rgba(0, 0, 0, 0.5)' : 'transparent',
    pointerEvents: toggle ? 'auto' : 'none',

  });

  const windowAnimation = useSpring({
    backgroundColor: toggle ? 'white' : 'transparent',
    pointerEvents: toggle ? 'auto' : 'none',
    transform: toggle ? 'translateY(-50px)' : 'translateY(0px)',
    config: config.wobbly,
  })

  useEffect(() => {
    setEnable(toggle)
  }, [toggle])

  useEffect(() => {
    if (!isSyncCounties) return

    checkCountriesFromTracker()
  }, [countriesSelected])

  useEffect(() => {
    const start = () => {
      //
      const savedData = loadSettingsItems()
      if(savedData){
        setSettingItems(savedData)
      }
    }

    start()
  },[])
  return (
    <animated.article
      style={{
        ...backgroundAnimation,
        paddingTop: '100px',
        display: enable ? 'flex' : 'none',
        justifyContent: 'center',
        alignItems: 'center',

        position: 'fixed',
        top: '0px',
        left: '0px',
        width: '100vw',
        height: '100vh',
        zIndex: 100
      }}>
      <animated.section
        style={{
          ...windowAnimation,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: '10px',

          width: '600px',
          height: '700px',
          borderRadius: '10px',
          padding: '10px',
          overflowY: 'auto'
        }}>
        <section
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            height: '50px',
            minHeight: '50px',
            paddingLeft: '10px',
            paddingRight: '10px',
            marginTop: '5px'
          }}>
          <section
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '20px'
            }}>

            <button
              onClick={() => { setCurrentWindow('settings') }}
              style={{
                fontSize: '20px',
                backgroundColor: 'transparent',
                border: 'none',
                paddingBottom: '3px',
                borderBottom: currentWindow === 'settings' ? '2px solid black' : 'none',
              }}>Налаштування</button>

            {/* {console.log(user)} */}
            {user.user === 'admin' && (
              <button
                onClick={() => { setCurrentWindow('admin') }}
                style={{
                  fontSize: '20px',
                  backgroundColor: 'transparent',
                  border: 'none',
                  paddingBottom: '3px',
                  borderBottom: currentWindow === 'admin' ? '2px solid black' : 'none',
                }}>Адмін панель</button>
            )}
          </section>

          <button onClick={() => setSettingsWindowToggle(!toggle)}
            style={{
              height: '70%',
              aspectRatio: '1/1',
              borderRadius: '100%',
              border: 'none'
            }}>
            <p style={{ fontSize: '20px' }}>X</p>
          </button>

        </section>


        {/* SETTINGS WINDOW */}
        {currentWindow === 'settings' && (
          <>
            <ColorChanger />

            <table style={{
              width: '100%'
            }}>
              <tbody>
                <tr>
                  <th style={{ textAlign: 'left' }}>Період</th>
                  <th style={{ ...flexRow, height: '50px', justifyContent: 'flex-start' }}><DateRangePicker changeSelectedDate={changeSelectedDate} changeFilterDataRange={changeFilterDataRange} /></th>
                </tr>
              </tbody>
            </table>
            <table style={{
              width: '100%'
            }}>
              <thead>
                <tr>
                  <th style={{ textAlign: 'left' }}>Країна</th>
                  <th style={{ textAlign: 'left' }}>Ліди</th>
                  <th style={{ textAlign: 'left' }}>FTD's</th>
                  <th style={{ textAlign: 'left' }}>Ціна за 1 FTD</th>
                </tr>
              </thead>
              <tbody>
                {settingItems.map(item => (
                  <tr>
                    <th className="montserrat" style={{ textAlign: 'left' }}>
                      <section style={{
                        display: 'flex',
                        maxWidth: '150px',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        gap: '5px',
                      }}>
                        {item.country}
                        {item.hasCustomDate ?
                          (
                            <section style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}>
                              <DatePickerForCountry subId={item.subId} addCustomDate={addCountryCustomDate} />
                              <button onClick={() => { toggleCountryCustomDate(item.subId) }} style={{
                                width: '15px',
                                height: '15px',
                                backgroundColor: 'red',
                                borderRadius: '100%',
                                border: 'none',
                                color: 'white',
                              }}>X</button>
                            </section>
                          )
                          :
                          <button
                            onClick={() => { toggleCountryCustomDate(item.subId) }}
                            style={{

                            }}>Вибрати період</button>
                        }
                      </section>
                    </th>
                    <th style={{ textAlign: 'left' }}>
                      <input value={item.leads} style={{ ...input }} onChange={(e) => editSettingItem(item.subId, 'leads', e.target.value)}></input>
                    </th>
                    <th style={{ textAlign: 'left' }}><input value={item.payouts} style={{ ...input }} onChange={(e) => editSettingItem(item.subId, 'payouts', e.target.value)}></input></th>
                    <th style={{ textAlign: 'left' }}>
                      <section style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '10px',
                      }}>
                        <input value={item.profitPerPayout} style={{ ...input }} onChange={(e) => editSettingItem(item.subId, 'profitPerPayout', e.target.value)}></input>
                        {!isSyncCounties && <button onClick={() => { removeSettingItem(item.subId) }} style={{
                          width: '20px',
                          height: '20px',
                          backgroundColor: 'red',
                          borderRadius: '100%',
                          border: 'none',
                          color: 'white',
                        }}>X</button>
                        }
                      </section>
                    </th>
                  </tr>
                ))}
              </tbody>
            </table>
            <section style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '10px',
              width: '100%',
              borderTop: '2px solid #92929269',
              paddingTop: '10px',
              marginTop: '10px',
            }}>

              <p>Загально:</p>
              <p>Лідів: {allLeads}</p>
              <p>Виплат: {allPayouts}</p>
              <p>Конверсія: {allLeads !== 0 && allPayouts !== 0 ? (allPayouts / allLeads * 100).toFixed(2) : 0}%</p>
            </section>
            {!isSyncCounties && <section style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              gap: '20px',
              width: '100%'
            }}>
              <input list="countries" value={addCountryTitle} onChange={(e) => setAddCountryTitle(e.target.value)} placeholder='Країна...'></input>
              <datalist id="countries">
                {allCountries.map((country, index) => (
                  <option key={index} value={country} />
                ))}
              </datalist>
              <button onClick={() => { addNewCountry(addCountryTitle) }} style={{
                minHeight: '32px',
                width: '150px',
                borderRadius: '5px',
                border: 'none',
                backgroundColor: addCountryTitle.length >= 1 ? 'blue' : 'rgba(0,0,0,0.3)',
                pointerEvents: addCountryTitle.length >= 1 ? 'auto' : 'none',
                borderRadius: '5px',
                fontSize: '15px',
                color: 'white'
              }}>Додати</button>
            </section>
            }
            <section style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              width: '100%'
            }}>
              <button onClick={() => { generateNewGraphData() }} style={{
                minHeight: '50px',
                width: '150px',
                borderRadius: '5px',
                border: 'none',
                backgroundColor: 'green',
                borderRadius: '5px',
                fontSize: '15px',
                color: 'white'
              }}>Обновити</button>
              <section style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100px',
              }}>
                <p>Синхронізувувати країни з трекера:</p>
                <input
                  type="checkbox"
                  checked={isSyncCounties}
                  onChange={(e) => switchSyncCountries(e.target.checked)}
                  style={{
                    marginLeft: '30px',
                    transform: 'scale(1.5)',
                    cursor: 'pointer',
                  }}
                />
              </section>
            </section>
            <p>{generatingStatusText}</p>
          </>
        )}
        {/* ADMIN WINDOW */}
        {currentWindow === 'admin' && (
          <section style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            marginTop: '20px',
            gap: '10px',
            width: '100%',
          }}>
            <section style={{ width: '100%' }}>
              <Token currentToken={user.token} />
              <Password currentPassword={user.password} />
            </section>
            <UsersManager />
          </section>
        )}
      </animated.section>

    </animated.article >
  )
}
// ADMIN PANEL COMPONENTS
const Token = ({ currentToken }) => {
  const [state, setState] = useState('default')
  const [token, setToken] = useState(currentToken)
  const [newToken, setNewTokenn] = useState('')


  const [changeTokenStatus, setChangeTokenStatus] = useState('')
  const updateToken = async (newToken) => {
    if (newToken === '') {
      alert('Токен не може бути пустим')
      return
    }
    const isValidToken = /^[A-Za-z0-9]+$/.test(newToken);
    if (!isValidToken) {
      alert('Токен може містити лише букви та цифри, без пробілів!');
      return;
    }
    const isConfirmed = window.confirm('Ви дійсно хочете змінити токен?');
    if (isConfirmed) {
      setChangeTokenStatus('Зміна...')
      const result = await changeToken(newToken)
      if (result) {
        setToken(newToken)
        setNewTokenn('')
        setState('default')
        setChangeTokenStatus('Змінено!')
      }
      else setChangeTokenStatus('Помилка...')
    }

  }

  return (
    <table style={{
      margin: '0px',
      width: '100%'
    }}>
      <tbody>
        <tr>
          <th
            style={{ textAlign: 'left', maxWidth: '50px' }}>
            Токен:
            <p style={{ fontSize: '10px' }}>{changeTokenStatus}</p>
          </th>
          <th style={{ minWidth: '225px', maxWidth: '225px' }}>
            <section
              style={{
                fontSize: '12px',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
              }}>
              {state === 'default' && (
                <p style={{ paddingLeft: '20px', wordBreak: 'break-word' }}>{token}</p>
              )}
              {state === 'change' && (
                <>
                  <input
                    value={newToken}
                    placeholder='Новий токен...'
                    style={{
                      width: '100%',
                      height: '40px',
                    }}
                    onChange={(e) => setNewTokenn(e.target.value)}></input>
                </>
              )}
            </section>
          </th>
          <th style={{ maxWidth: '65px' }}>
            <section
              style={{
                ...flexRow,
                height: '100%',
                width: '100%',
                justifyContent: 'flex-end',
                alignItems: 'center',
                gap: '10px',
              }}>
              {state === 'default' && (
                <button
                  onClick={() => setState('change')}
                  style={{
                    border: 'none',
                    borderRadius: '100%',
                    backgroundColor: 'transparent',
                    width: '30px',
                    height: '30px',
                  }}>
                  <p className="material-symbols-outlined">edit</p>
                </button>
              )}
              {state === 'change' && (
                <>
                  <button
                    onClick={() => updateToken(newToken)}
                    style={{
                      border: 'none',
                      borderRadius: '100%',
                      backgroundColor: 'green',
                      width: '30px',
                      height: '30px',
                      color: 'white',
                    }}>
                    <p className="material-symbols-outlined">done</p>
                  </button>
                  <button
                    onClick={() => setState('default')}
                    style={{
                      border: 'none',
                      borderRadius: '100%',
                      backgroundColor: 'red',
                      width: '30px',
                      height: '30px',
                      color: 'white',
                    }}>
                    <p className="material-symbols-outlined">close</p>
                  </button>
                </>
              )}

            </section>

          </th>
        </tr>
      </tbody>
    </table>
  )
}
const Password = ({ currentPassword }) => {
  const [state, setState] = useState('default')
  const [password, setPassword] = useState(currentPassword)
  const [newPassword, setNewPassword] = useState('')


  const [changePasswordStatus, setChangePasswordStatus] = useState('')
  const updatePassword = async (newPassword) => {
    if (newPassword === '') {
      alert('Пароль не може бути пустим')
      return
    }
    const isConfirmed = window.confirm('Ви дійсно хочете змінити пароль?');
    if (isConfirmed) {
      setChangePasswordStatus('Зміна...')
      const result = await changePassword(newPassword)
      if (result) {
        setPassword(newPassword)
        setNewPassword('')
        setState('default')
        setChangePasswordStatus('Змінено!')
      }
      else setChangePasswordStatus('Помилка...')
    }

  }

  return (
    <table style={{
      margin: '0px',
      width: '100%'
    }}>
      <tbody>
        <tr>
          <th
            style={{ textAlign: 'left', maxWidth: '50px' }}>
            Пароль:
            <p style={{ fontSize: '10px' }}>{changePasswordStatus}</p>
          </th>
          <th style={{ minWidth: '225px', maxWidth: '225px' }}>
            <section
              style={{
                fontSize: '12px',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
              }}>
              {state === 'default' && (
                <p style={{ paddingLeft: '20px', wordBreak: 'break-word' }}>{password}</p>
              )}
              {state === 'change' && (
                <>
                  <input
                    value={newPassword}
                    placeholder='Новий пароль...'
                    style={{
                      width: '100%',
                      height: '40px',
                    }}
                    onChange={(e) => setNewPassword(e.target.value)}></input>
                </>
              )}
            </section>
          </th>
          <th style={{ maxWidth: '65px' }}>
            <section
              style={{
                ...flexRow,
                height: '100%',
                width: '100%',
                justifyContent: 'flex-end',
                alignItems: 'center',
                gap: '10px',
              }}>
              {state === 'default' && (
                <button
                  onClick={() => setState('change')}
                  style={{
                    border: 'none',
                    borderRadius: '100%',
                    backgroundColor: 'transparent',
                    width: '30px',
                    height: '30px',
                  }}>
                  <p className="material-symbols-outlined">edit</p>
                </button>
              )}
              {state === 'change' && (
                <>
                  <button
                    onClick={() => updatePassword(newPassword)}
                    style={{
                      border: 'none',
                      borderRadius: '100%',
                      backgroundColor: 'green',
                      width: '30px',
                      height: '30px',
                      color: 'white',
                    }}>
                    <p className="material-symbols-outlined">done</p>
                  </button>
                  <button
                    onClick={() => setState('default')}
                    style={{
                      border: 'none',
                      borderRadius: '100%',
                      backgroundColor: 'red',
                      width: '30px',
                      height: '30px',
                      color: 'white',
                    }}>
                    <p className="material-symbols-outlined">close</p>
                  </button>
                </>
              )}

            </section>

          </th>
        </tr>
      </tbody>
    </table>
  )
}
const UsersManager = () => {
  const [users, setUsers] = useState(null)
  const loadUsers = async () => {
    const loadedUsers = await getUsers()
    setUsers(loadedUsers)
  }
  useEffect(() => {
    loadUsers()
  }, [])

  const deleteUser = async (login) => {
    const isConfirmed = window.confirm(`Ви дійсно хочете видалити цього користувача - ${login}?`);
    if (isConfirmed) {
      const removeResult = await removeUser(login)
      if (removeResult) {
        loadUsers()
        alert('Користувача видалено!')
      } else {
        alert('Помилка під час видалення користувача')
      }
    }
  }

  const addUser = (login, password) => {

  }

  return (
    <>
      <table style={{
        width: '100%'
      }}>
        <thead>
          <tr>
            <th style={{ textAlign: 'left' }}>ID</th>
            <th style={{ textAlign: 'left' }}>Логін</th>
            <th style={{ textAlign: 'left' }}>Пароль</th>
            <th style={{ textAlign: 'left' }}></th>
          </tr>
        </thead>

        <tbody>
          {users && users.map((u, index) => (
            <tr>
              <th className="montserrat" style={{ textAlign: 'left' }}>{index}</th>
              <th style={{ textAlign: 'left' }}><p>{u.login}</p></th>
              <th style={{ textAlign: 'left' }}><p>{u.password}</p></th>
              <th style={{ textAlign: 'left' }}>
                <button onClick={() => { deleteUser(u.login) }} style={{
                  width: '20px',
                  height: '20px',
                  backgroundColor: 'red',
                  borderRadius: '100%',
                  border: 'none',
                  color: 'white',
                }}>X</button>
              </th>
            </tr>
          ))}
        </tbody>
      </table>
      {users == null && (
        <section style={{
          width: '100%',
          height: '50px'
        }}>
          <p style={{ width: '100%', textAlign: 'center' }}>Загрузка користувачів...</p>
        </section>
      )}
      {users && users.length == 0 && (
        <section style={{
          width: '100%',
          height: '50px'
        }}>
          <p style={{ width: '100%', textAlign: 'center' }}>Немає користувачів</p>
        </section>
      )}
      <AddNewUser updateUsers={loadUsers} />
    </>
  )
}
const AddNewUser = ({ updateUsers }) => {
  const [newUserLogin, setNewUserLogin] = useState('')
  const [newUserPassword, setNewUserPassword] = useState('')

  const addNewUser = async () => {
    const isConfirmed = window.confirm(`Ви дійсно хочете добавити нового користувача - логін: ${newUserLogin} пароль: ${newUserPassword}?`);
    if (isConfirmed) {
      const addResult = await addUser(newUserLogin, newUserPassword)
      if (addResult) {
        updateUsers()
        alert('Користувача додано!')
        setNewUserLogin('')
        setNewUserPassword('')
      } else {
        alert('Помилка під час додавання користувача')
      }
    }
  }

  return (
    <table style={{
      margin: '0px',
      width: '100%'
    }}>
      <tbody>
        <tr>
          <th style={{ minWidth: '225px', maxWidth: '225px' }}>
            <section
              style={{
                fontSize: '12px',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
              }}>
              <input
                value={newUserLogin}
                placeholder='Логін...'
                style={{
                  width: '100%',
                  height: '40px',
                }}
                onChange={(e) => setNewUserLogin(e.target.value)}></input>
            </section>
          </th>
          <th style={{ minWidth: '225px', maxWidth: '225px' }}>
            <section
              style={{
                fontSize: '12px',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
              }}>
              <input
                value={newUserPassword}
                placeholder='Пароль...'
                style={{
                  width: '100%',
                  height: '40px',
                }}
                onChange={(e) => setNewUserPassword(e.target.value)}></input>
            </section>
          </th>
          <th style={{ maxWidth: '65px' }}>
            <section
              style={{
                ...flexRow,
                height: '100%',
                width: '100%',
                justifyContent: 'flex-end',
                alignItems: 'center',
                gap: '10px',
              }}>
              <button
                onClick={addNewUser}
                style={{
                  border: 'none',
                  borderRadius: '100%',
                  backgroundColor: 'transparent',
                  width: '30px',
                  height: '30px',
                }}>
                <p className="material-symbols-outlined">add</p>
              </button>
            </section>

          </th>
        </tr>
      </tbody>
    </table>
  )
}
const CustomInputForCountryDatePicker = forwardRef(({ value, onClick }, ref) => {
  const formattedValue = value.replace(/ - /g, ' ~ ');
  const [isHovered, setIsHovered] = useState(false);

  const hoverColor = '#1f2227'

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      width: '120px',
    }}

      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <input
        style={{
          backgroundColor: 'transparent',
          border: 'none',
          color: 'black',
          fontSize: '12px',
          width: '100%',
          height: '100%'
        }}
        value={formattedValue}
        onClick={onClick}
        ref={ref}
        readOnly
      />
    </div>
  );
});
const DatePickerForCountry = ({ subId, addCustomDate }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
      addCustomDate(subId, [start.getTime(), end.getTime()])
    }
  };

  return (
    <DatePicker
      selectsRange
      startDate={startDate}
      endDate={endDate}
      onChange={handleChange}
      customInput={<CustomInputForCountryDatePicker />}
      dateFormat="dd/MM/yy"
      monthsShown={2}
      isClearable={true}
    />
  );
}
const CustomInput = forwardRef(({ value, onClick }, ref) => {
  const formattedValue = value.replace(/ - /g, ' ~ ');
  const [isHovered, setIsHovered] = useState(false);

  const hoverColor = '#1f2227'

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      // gap: '10px',
      // backgroundColor: !isHovered ? 'transparent' : 'rgba(0,0,0,0.1)',
      // border: '1px solid black',
      padding: '15px',
      borderRadius: '10px',
    }}

      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <input
        style={{
          backgroundColor: 'transparent',
          border: 'none',
          color: 'black',
          fontSize: '12px',
          width: '100%',
          height: '100%'
        }}
        value={formattedValue}
        onClick={onClick}
        ref={ref}
        readOnly
      />
    </div>
  );
});
const DateRangePicker = ({ changeSelectedDate }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
      changeSelectedDate([start.getTime(), end.getTime()]);
    }
  };

  return (
    <DatePicker
      selectsRange
      startDate={startDate}
      endDate={endDate}
      onChange={handleChange}
      customInput={<CustomInput />}
      dateFormat="dd/MM/yy"
      monthsShown={2}
      isClearable={true}
    />
  );
};
const flexRow = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  textAlign: 'left'
}
const flexCenter = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'left'
}
const input = {
  border: 'none',
  // borderBottom: '1px solid #92929269',
  backgroundColor: 'transparent',
  fontSize: '15px',
  maxWidth: '70px'
}
const tableStyle = {
  padding: '10px'
}
const tableRow = {
  paddingTop: '10px'
}

