const initialState = {
  graphData: [],
  selectedCountries: [],
  selectedDate: ['11.02.2023', '11.03.2023'],
  selectedColor: 'purple',
  colors: {
    azure: {
      fixed: '#06fbaf',
      gradienStart: '#03d5f6',
      gradientEnd: '#06f9ae',
      lead: '#04d4fa',
      impressions: '#01fead',
      ftds: '#7ad7f3',
      click: '#bcbabb',
      headerBgColor:'#03d5f6',
      dropDownSelected: '#75d9f6'
    },

    blue: {
      fixed: '#0534dc',
      gradienStart: '#4876f8',
      gradientEnd: '#0e2ee0',
      lead: '#4a77f4',
      impressions: '#0733dc',
      ftds: '#75a2f5',
      click: '#bbbbb9',
      dropDownSelected: '#769ff7'
    },
    pink: {
      fixed: '#e1109f',
      gradienStart: '#fb83d6',
      gradientEnd: '#e7109c',
      lead: '#ff84d8',
      impressions: '#eb0d9b',
      ftds: '#f9d0eb',
      click: '#a8acaf',
      dropDownSelected: '#facfea'
    },
    yellow: {
      fixed: '#fe8c00',
      gradienStart: '#fcb914',
      gradientEnd: '#fe8c01',
      lead: '#f6b405',
      impressions: '#fc8f07',
      ftds: '#ffd66a',
      click: '#bdbdbf',
      dropDownSelected: '#ffd766'
    },
    green: {
      fixed: '#066c2a',
      gradienStart: '#27ae54',
      gradientEnd: '#076c2c',
      lead: '#23b65a',
      impressions: '#086b32',
      ftds: '#7bd494',
      click: '#b9bbb9',
      dropDownSelected: '#75d697'
    },
    purple: {
      fixed: '#661ea8',
      gradienStart: '#9544de',
      gradientEnd: '#6d1aaa',
      lead: '#9648e1',
      impressions: '#691ca0',
      ftds: '#c487f7',
      click: '#bababc',
      dropDownSelected: '#c186f9'
    },
    red: {
      fixed: '#fa2e6e',
      gradienStart: '#ed2e6c',
      gradientEnd: '#b40740',
      lead: '#db366a',
      impressions: '#b00c40',
      ftds: '#de5a8b',
      click: '#bcbdbf',
      dropDownSelected: '#fa578c'
    }
  },
  globalGraphData:[]
};

const SET_SELECTED_COUNTRIES = 'SET_SELECTED_COUNTRIES'
const SET_SELECTED_DATES = 'SET_SELECTED_DATES'
const SET_NEW_COLOR = 'SET_NEW_COLOR'
const SET_GLOBAL_GRAPH_DATA = 'SET_GLOBAL_GRAPH_DATA'

export const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_COUNTRIES:
      return { ...state, selectedCountries: action.payload }
    case SET_SELECTED_DATES:
      return { ...state, selectedDate: action.payload }
    case SET_NEW_COLOR:
      return { ...state, selectedColor: action.payload }
      case SET_GLOBAL_GRAPH_DATA:
        console.log('action.payload',action.payload);
        return { ...state, globalGraphData: action.payload }
    default:
      return state;
  }
}

export const setSelectedCountriesAction = (payload) => ({ type: SET_SELECTED_COUNTRIES, payload });
export const setSelectedDatesAction = (payload) => ({ type: SET_SELECTED_DATES, payload });
export const setNewColorAction = (payload) => ({ type: SET_NEW_COLOR, payload });
export const setGlobalGraphData = (payload) => ({ type: SET_GLOBAL_GRAPH_DATA, payload });


