import React, { useState, useEffect } from 'react'
//Вікна
import MainWindow from './components/Windows/MainWindow'

import Header from './components/Header'
import MainPage from './components/MainPage'
import Login from './components/Windows/Login'

import { api_url, setOriginDomain, isUserAuthenticated, authenticateUser } from './servises/admin'

export default function App() {

  useEffect(() => {
    const originDomain = window.location.origin;
    let combinedPath;

    if (document.baseURI) {
      let baseHref = document.baseURI;
      if (baseHref.endsWith('index.html')) {
        baseHref = baseHref.substring(0, baseHref.length - 'index.html'.length);
      }
      baseHref = baseHref.replace(originDomain, '');
      combinedPath = originDomain + baseHref;
    } else {
      combinedPath = originDomain;
    }
    setOriginDomain(combinedPath)
  }, [])


  return (
    <>
      {isUserAuthenticated() ? (
        <>
          <MainWindow />
        </>
      ) :
        (
          <Login/>
        )}
    </>
  )
}

