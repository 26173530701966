import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';


const Graph = ({ labels, dataLeads, dataImpressions, dataFTDs, dataClicks, graphInfoData, update, filterDatesSelect }) => {

    const selectedColor = useSelector(state => state.settings.selectedColor);
    const colors = useSelector(state => state.settings.colors);

    const data = {
        labels,
        datasets: [
            {
                label: 'Leads',
                data: dataLeads,
                fill: false,
                backgroundColor: colors[selectedColor].lead,
                borderColor: colors[selectedColor].lead,
            },
            {
                label: 'Impressions',
                data: dataImpressions,
                fill: false,
                backgroundColor: colors[selectedColor].impressions,
                borderColor: colors[selectedColor].impressions,
            },
            {
                label: "FTD's",
                data: dataFTDs,
                fill: false,
                backgroundColor: colors[selectedColor].ftds,
                borderColor: colors[selectedColor].ftds,
            },
            {
                label: 'Clicks',
                data: dataClicks,
                fill: false,
                backgroundColor: colors[selectedColor].click,
                borderColor: colors[selectedColor].click,
            },
        ],
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true
            }
        },
        responsive: true,
    };

    const [chartKey, setChartKey] = useState(0);

    useEffect(() => {
        setChartKey(prevKey => prevKey + 1);
    }, [update]);

    return (
        <section style={{
            width: '100%',
            backgroundColor: '#272a32',
            borderRadius: '10px',
        }}>
            <GraphStatisticSection graphInfoData={graphInfoData} />
            <section style={{
                height: '100%',
                width: '100%',
                padding: '5px',
            }}>
                <Line key={chartKey} data={data} options={options} width='100vw' height='24%' />
            </section>
        </section>
    )
}

export default Graph;

const GraphStatisticSection = ({ graphInfoData }) => {
    const leadsFirstElement = Math.round(graphInfoData.leads); // Округлення до найближчого цілого
    const leadsSecondElement = Math.round(leadsFirstElement / 50); // В 20 разів менше
    const selectedColor = useSelector(state => state.settings.selectedColor);
    const colors = useSelector(state => state.settings.colors);

    const leads = [leadsFirstElement, leadsSecondElement];
    const conversionValue = isNaN(graphInfoData.conversions) || graphInfoData.conversions === null || graphInfoData.conversions === undefined ? '0' : graphInfoData.conversions.toFixed(2);

    return (
        <section style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',

            width: '100%',
            height: '100px',
            backgroundColor: '#272a32'
        }}>
            <GraphStatisticSectionItem title='Impressions' values={[{ value: graphInfoData.impressions, title: '' }]} titleColor={colors[selectedColor].gradienStart} decorator={true} />
            <GraphStatisticSectionItem title='Clicks' values={[{ value: graphInfoData.clicks, title: '' }]} titleColor={colors[selectedColor].gradienStart} decorator={true} />
            <GraphStatisticSectionItem title='Leads' values={[{ value: leads[0], title: 'Qualified' }, { value: leads[1], title: 'Failed' }]} decorator={true} />
            <GraphStatisticSectionItem title="FTD's" values={[{ value: graphInfoData.ftds, title: '' }]} decorator={true} />
            <GraphStatisticSectionItem title='Conversion' values={[{ value: `${conversionValue}%`, title: '' }]} valueColor={colors[selectedColor].gradienStart} decorator={false} />
            <GraphStatisticSectionItem title='Payout' type='payout' values={[{ value: graphInfoData.payout, title: '' }]} article='$' decorator={false} />
        </section>
    )
}
const GraphStatisticSectionItem = ({ type, title, values, decorator, titleColor, valueColor, article }) => {
    const selectedColor = useSelector(state => state.settings.selectedColor);
    const colors = useSelector(state => state.settings.colors);
    const [isHovered, setIsHovered] = useState(false);

    return (
        <section style={{
            flex: 1,
            height: '100%',
            borderLeft: decorator ? '1px solid #444444' : 'none',
            borderRight: decorator ? '1px solid #444444' : 'none',
            backgroundColor: isHovered ? '#52555b' : 'transparent'
        }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}>

            {decorator && (
                <section style={{
                    position: 'relative',

                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    width: '100%',
                }}>
                    <div style={{
                        position: 'absolute',
                        top: '0px',
                        left: '0px',
                        height: '3px',
                        width: '100%',
                        backgroundImage: `linear-gradient(to right, ${colors[selectedColor].gradienStart}, ${colors[selectedColor].gradientEnd})`
                    }}>

                    </div>
                    <span className="material-symbols-outlined" style={{
                        position: 'absolute',
                        top: '-8.8px',
                        fontSize: '28px',
                        color: colors[selectedColor].gradienStart
                    }}>arrow_drop_down</span>

                </section>
            )}
            <section style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '3px',
                marginTop: '12px',
            }}>
                <p style={{ fontSize: '12px', fontWeight: '500', color: titleColor ? titleColor : 'white' }}>{title}</p>
                <section style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                }}>
                    {values.map((v, index) => (
                        <section key={index} style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',

                            flex: 1,
                            height: '100%'
                        }}>
                            <p style={{ fontSize: '30px', color: valueColor ? valueColor : 'white' }}>{`${article ? article : ''}${type === 'payout' ? v.value == '0' ? '0.00' : v.value : v.value}`}</p>
                            <p style={{ fontSize: '12px', fontWeight: '500', color: '#7e7e7e' }}>{v.title}</p>
                        </section>
                    ))}
                </section>
            </section>
        </section>
    )
}
